import * as Colors from "../../../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem",
    textAlign: "center"
};

export const changeOfSenior = {
    fontSize: "1.3rem"
};

export const passwordHelpText = {
    color: "red"
};

export const contactSent = {
    fontSize: "1.3rem"
};
