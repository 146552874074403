export const UpdateUUID = (val) => {
    return {
        type: 'UPDATE_ADMINU_UUID',
        value: val
    };
}

export const UpdateFromDetails = (val) => {
    return {
        type: 'UPDATE_ADMINU_FROMDETAILS',
        value: val
    }
}