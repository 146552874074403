import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function ForgotPassword() {
    const params = useParams();

    const navigate = useNavigate();
    const [message, setmessage] = useState("");
    const [sent, setSent] = useState(false);

    useEffect(() => {
        document.title = "STAFF";
    }, []);

    function requestPassword(event) {
        event.preventDefault();

        const email = document.getElementById("txtEmail").value;

        if (email != "") {
            const data = { email: email, resetUserPasswords: false };
            Axios.post("/security/requestPassword", data)
                .then((res) => {
                    if (res.data.userError == "Yes") {
                        setmessage(res.data.message);
                    } else {
                        setSent(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>Forgot your password?</h1>
                    {sent ? (
                        <div>
                            <h3>Your new password has been requested, please check your email</h3>
                            <Button variant="warning" onClick={() => navigate("/org/" + params.orgID + "/signIn")}>
                                Back to Sign In
                            </Button>
                            <br />
                            <br />
                        </div>
                    ) : (
                        <div>
                            <h3>Please enter your email below:</h3>
                            <br />
                            <form>
                                <Form.Control id="txtEmail" type="email" required />
                                <br /> <br />
                                <p>{message}</p>
                                <Button type="submit" variant="warning" onClick={requestPassword}>
                                    Request New Password
                                </Button>
                                <br />
                                <br />
                            </form>
                            <br />
                        </div>
                    )}
                </CenterContainer>
            </Container>
        </div>
    );
}

export default ForgotPassword;
