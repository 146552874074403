export const ORG_UPDATE_NAME = "ORG_UPDATE_NAME";
export const ORG_UPDATE_SIGNIN_LOCAL = "ORG_UPDATE_SIGNIN_LOCAL";
export const ORG_UPDATE_SIGNIN_GOOGLE = "ORG_UPDATE_SIGNIN_GOOGLE";
export const ORG_UPDATE_MESSAGE = "ORG_UPDATE_MESSAGE";
export const ORG_UPDATE_USEDEPARTMENTS = "ORG_UPDATE_USEDEPARTMENTS";
export const ORG_UPDATE_NOOFDEPARTMENTS = "ORG_UPDATE_NOOFDEPARTMENTS";
export const ORG_UPDATE_DEPARTMENTS = "ORG_UPDATE_DEPARTMENTS";
export const ORG_UPDATE_ALLOCATEDROOMS = "ORG_UPDATE_ALLOCATEDROOMS";
export const ORG_UPDATE_REDEEMEDROOMS = "ORG_UPDATE_REDEEMEDROOMS";
export const ORG_UPDATE_ROOMS = "ORG_UPDATE_ROOMS";
export const ORG_UPDATE_LAYOUTS = "ORG_UPDATE_LAYOUTS";
export const ORG_UPDATE_HOLIDAYS = "ORG_UPDATE_HOLIDAYS";
export const ORG_UPDATE_LOCKED = "ORG_UPDATE_LOCKED";
export const ORG_UPDATE_NEWUSERPASSWORDS = "ORG_UPDATE_NEWUSERPASSWORDS";
export const ORG_UPDATE_RESETUSERPASSWORDS = "ORG_UPDATE_RESETUSERPASSWORDS";

export const orgUpdateName = (val) => {
	return {
		type: ORG_UPDATE_NAME,
		value: val,
	};
};

export const orgUpdateSignInLocal = (val) => {
	return {
		type: ORG_UPDATE_SIGNIN_LOCAL,
		value: val,
	};
};

export const orgUpdateSignInGoogle = (val) => {
	return {
		type: ORG_UPDATE_SIGNIN_GOOGLE,
		value: val,
	};
};

export const orgUpdateMessage = (val) => {
	return {
		type: ORG_UPDATE_MESSAGE,
		value: val,
	};
};

export const orgUpdateUseDepartments = (val) => {
	return {
		type: ORG_UPDATE_USEDEPARTMENTS,
		value: val,
	};
};

export const orgUpdateNoOfDepartments = (val) => {
	return {
		type: ORG_UPDATE_NOOFDEPARTMENTS,
		value: val,
	};
};

export const orgUpdateDepartments = (val) => {
	return {
		type: ORG_UPDATE_DEPARTMENTS,
		value: val,
	};
};

export const orgUpdateAllocatedRooms = (val) => {
	return {
		type: ORG_UPDATE_ALLOCATEDROOMS,
		value: val,
	};
};

export const orgUpdateRedeemedRooms = (val) => {
	return {
		type: ORG_UPDATE_REDEEMEDROOMS,
		value: val,
	};
};

export const orgUpdateRooms = (val) => {
	return {
		type: ORG_UPDATE_ROOMS,
		value: val,
	};
};

export const orgUpdateLayouts = (val) => {
	return {
		type: ORG_UPDATE_LAYOUTS,
		value: val,
	};
};

export const orgUpdateHolidays = (val) => {
	return {
		type: ORG_UPDATE_HOLIDAYS,
		value: val,
	};
};

export const orgUpdateLocked = (val) => {
	return {
		type: ORG_UPDATE_LOCKED,
		value: val,
	};
};

export const orgUpdateNewUserPasswords = (val) => {
	return {
		type: ORG_UPDATE_NEWUSERPASSWORDS,
		value: val,
	};
};

export const orgUpdateResetUserPasswords = (val) => {
	return {
		type: ORG_UPDATE_RESETUSERPASSWORDS,
		value: val,
	};
};
