import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function WrongOrganisation() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "STAFF - Not Connected";
    }, []);

    function toOrgSignIn() {
        console.log("click clock");
        navigate("/", { replace: true });
    }

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h3>Sorry, this is not your organisation</h3>
                    <br /> <br />
                    <Button variant="warning" onClick={toOrgSignIn}>
                        Back to my organisation
                    </Button>
                    <br />
                    <br />
                </CenterContainer>
            </Container>
        </div>
    );
}

export default WrongOrganisation;
