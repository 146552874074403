export const DEFAULT_SETTINGS = "DEFAULT_SETTINGS";

export const UPUPDATE_ROOM_VIEW = "UPUPDATE_ROOM_VIEW";
export const UPUPDATE_ROOM_WRITE = "UPUPDATE_ROOM_WRITE";
export const UPUPDATE_ROOM_EDIT = "UPUPDATE_ROOM_EDIT";
export const UPUPDATE_ROOM_DELETE = "UPUPDATE_ROOM_DELETE";
export const UPUPDATE_ROOM_REPEAT = "UPUPDATE_ROOM_REPEAT";

export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const RESET_STATE = "RESET_STATE";

export const DefaultSettings = () => {
	return (dispatch, getStore) => {
		const role = getStore().user.role;

		dispatch({
			type: DEFAULT_SETTINGS,
			role: role,
		});
	};
};

export const UpdateRoom_View = (val) => {
	return {
		type: UPUPDATE_ROOM_VIEW,
		value: val,
	};
};

export const UpdateRoom_Write = (val) => {
	return {
		type: UPUPDATE_ROOM_WRITE,
		value: val,
	};
};

export const UpdateRoom_Edit = (val) => {
	return {
		type: "UPUPDATE_ROOM_EDIT",
		value: val,
	};
};

export const UpdateRoom_Delete = (val) => {
	return {
		type: UPUPDATE_ROOM_DELETE,
		value: val,
	};
};

export const UpdateRoom_Repeat = (val) => {
	return {
		type: UPUPDATE_ROOM_REPEAT,
		value: val,
	};
};

export const Update_User_Settings = (rooms, bookings, administrator, weeks) => {
	return {
		type: UPDATE_USER_SETTINGS,
		rooms: rooms,
		bookings: bookings,
		administrator: administrator,
		weeks: weeks,
	};
};

export const ResetState = () => {
	return (dispatch, getState) => {
		const defaultSettings = getState().UserProfileAdminGlobalSettings;
		const resetSettings = {};

		const sectionKeys = Object.keys(defaultSettings);

		for (const section of sectionKeys) {
			const settingKeys = Object.keys(defaultSettings[section]);
			const tempSection = {};

			for (const setting of settingKeys) {
				tempSection[setting] = defaultSettings[section][setting].value;
			}
			resetSettings[section] = tempSection;
		}

		console.log(resetSettings);

		dispatch({ type: RESET_STATE, defaultUserSettings: resetSettings });
	};
};
