import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

//COMPONENTS
import BookingItem from "./bookingItem";

//STYLES
import * as YourBookingsStyles from "../../styles/yourBookings";

function YourBookings(props) {
    const user = useSelector((state) => state.user);
    const [bookings, setBookings] = useState([]);

    const [order, setOrder] = useState("Up");

    function handleSortOrder() {
        if (order == "Up") {
            setOrder("Down");
        } else {
            setOrder("Up");
        }
    }

    useEffect(() => {
        let continu = false;
        for (const [key, value] of Object.entries(props.filters)) {
            if (value.name) {
                continu = true;
            }
        }
        if (continu) {
            const data = { filters: props.filters, orgID: user.orgID, operator: "OR", order };
            Axios.post("/bookingsFilters/getBookings", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setBookings(data.bookings);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setBookings([]);
        }
    }, [props.filters, order]);

    return (
        <div>
            <h1>Bookings</h1>
            <br />
            <Card>
                <Card.Body>
                    <Row>
                        <Col>Date</Col>
                        <Col>Room</Col>
                        <Col>Session</Col>
                        <Col>Class</Col>
                        <Col>Booking Type</Col>
                        <Col style={YourBookingsStyles.sortBox} onClick={handleSortOrder}>
                            {order == "Up" ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            {bookings.map((booking, index) => {
                return <BookingItem key={index} admin={false} details={booking} />;
            })}
        </div>
    );
}

export default YourBookings;
