import React, { useState, useEffect } from "react";
import { Collapse, Image, Row, Col, Form, Button, Modal, ListGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//SETTINGS
import * as AdminUsersSettings from "../../../../../../globalSettings/adminUserSettings";

//IMAGES
import plus from "../../../images/plus.png";
import minus from "../../../images/minus.png";

//STYLES
import * as OrganisationAdminStyles from "../../../styles/organisationAdmin";
import * as UsersStyles from "../styles/users";

function Users(props) {
    const orgID = props.orgID;
    const organisation = useSelector((state) => state.organisation);
    const AdminUserGlobalSettings = useSelector((state) => state.AdminUserGlobalSettings);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (AdminUserGlobalSettings.fromDetails) {
            setSettings((prevState) => {
                return { ...prevState, open: true };
            });
            getAllOrgUsersAndDepartments();

            AdminUsersSettings.setFromDetails(dispatch, false);
        }
    }, []);

    const [settings, setSettings] = useState({
        open: false,
        name: "",
        email: "",
        role_User: false,
        role_Admin: false,
        role_SeniorAdmin: false,
        multiAdd: false,
        departments: [],
        depToUser: "",
        userDepartments: [],
        users: [],
        edit: false,
        editID: 0,
        roles: true
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [userModal, setUserModal] = useState({
        open: false,
        heading: "",
        message: "",
        password: ""
    });

    function handleUserModalClose() {
        setUserModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function openTab() {
        if (!settings.open) {
            setSettings((prevState) => {
                return { ...prevState, open: true };
            });

            getAllOrgUsersAndDepartments();
        } else {
            setSettings((prevState) => {
                return { ...prevState, open: false };
            });
        }
    }

    function getAllOrgUsersAndDepartments() {
        const data = { orgID: orgID };

        Axios.post("/orgAdmin/users/getUsers", data)
            .then((res) => {
                console.log(res.data);

                const currentDepartments = [];
                for (const department of organisation.departments) {
                    currentDepartments.push({ name: department.name, active: false });
                }

                setSettings((prevState) => {
                    return { ...prevState, users: res.data.users, departments: currentDepartments };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleMultipleAdd() {
        setModal((prevState) => {
            return { ...prevState, heading: "Multiple Add", message: "The feature is not advailable yet", open: true };
        });
    }

    function handleSingleAdd() {
        if (settings.name == "" || settings.email == "") {
            setModal((prevState) => {
                return { ...prevState, heading: "Required", message: "Please fill in the name and email field.", open: true };
            });
        } else if (!settings.role_User && !settings.role_Admin) {
            setModal((prevState) => {
                return { ...prevState, heading: "Role", message: "Please pick a role.", open: true };
            });
        } else {
            const departments = ConvertDepartmentsToIDs();

            let role = "";
            if (settings.role_User) role = "user";
            if (settings.role_Admin) role = "admin";

            const data = {
                orgID: orgID,
                name: settings.name,
                email: settings.email,
                role: role,
                departments: departments,
                newUserPasswords: organisation.newUserPasswords
            };

            Axios.post("/orgAdmin/users/addUser", data)
                .then((res) => {
                    const data = res.data;

                    if (data.userError == "Yes") {
                        setModal((prevState) => {
                            return { ...prevState, heading: "Add User", message: data.message, open: true };
                        });
                    } else {
                        setSettings((prevState) => {
                            return {
                                ...prevState,
                                users: res.data.users,
                                name: "",
                                email: "",
                                role_User: false,
                                role_Admin: false,
                                userDepartments: []
                            };
                        });

                        if (data.showPassword) {
                            setUserModal((prevState) => {
                                return { ...prevState, heading: "Add User", message: data.message, password: data.password, open: true };
                            });
                        } else {
                            setModal((prevState) => {
                                return { ...prevState, heading: "Add User", message: data.message, open: true };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function ConvertDepartmentsToIDs() {
        let departmentIndex = [];

        for (const department of settings.userDepartments) {
            departmentIndex.push(organisation.departments.find((depart) => depart.name == department).uuid);
        }

        return departmentIndex.toString();
    }

    function ConvertIDsToDepartments(IDs) {
        let departmentArray = [];
        if (IDs != "") {
            const userDepartments = IDs.split(",");
            let updateDepartments = false;
            for (const department of userDepartments) {
                const depart = organisation.departments.find((depart) => depart.uuid == department);
                if (depart != null) {
                    departmentArray.push(depart.name);
                } else {
                    updateDepartments = true;
                }
            }

            if (updateDepartments) {
                let departmentsUpdated = "";
                for (const department of userDepartments) {
                    const depart = organisation.departments.find((depart) => depart.uuid == department);
                    if (depart != null) {
                        if (departmentsUpdated == "") {
                            departmentsUpdated = department;
                        } else {
                            departmentsUpdated = `${departmentsUpdated},${department}`;
                        }
                    }
                }
                setTimeout(() => {
                    const data = { uuid: settings.editID, departments: departmentsUpdated };
                    Axios.post("/orgAdmin/users/updateUsersDepartments", data)
                        .then((res) => {
                            const data = res.data;
                            console.log(data);
                        })
                        .catch((err) => console.log(err));
                }, 10);
            }
        }

        return departmentArray;
    }

    function handleRadioChange(event) {
        const { name } = event.target;

        if (name == "role_User") {
            setSettings((prevState) => {
                return { ...prevState, role_Admin: false, role_User: true };
            });
        }
        if (name == "role_Admin") {
            setSettings((prevState) => {
                return { ...prevState, role_Admin: true, role_User: false };
            });
        }
    }

    function handleChange(event) {
        const { name, value } = event.target;

        setSettings((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleClickedItem_Deparment(name) {
        for (const department of settings.departments) {
            department.active = false;
        }

        settings.departments.find((department) => department.name == name).active = true;

        setSettings((prevState) => {
            return { ...prevState, depToUser: name };
        });
    }

    function handleAddDepToUser() {
        if (!settings.userDepartments.includes(settings.depToUser)) {
            setSettings((prevState) => {
                return { ...prevState, userDepartments: [...settings.userDepartments, settings.depToUser] };
            });
        }
    }

    function hanldeRemoveDepFromUser() {
        if (settings.userDepartments.includes(settings.depToUser)) {
            const newDep = settings.userDepartments.filter((name) => {
                return name != settings.depToUser;
            });

            setSettings((prevState) => {
                return { ...prevState, userDepartments: newDep };
            });
        }
    }

    function handleClickedItem_User(uuid, name, email, role, departments) {
        setSettings((prevState) => {
            return { ...prevState, name: name, email: email, userDepartments: ConvertIDsToDepartments(departments), edit: true, editID: uuid };
        });

        if (role == "seniorAdmin") {
            setSettings((prevState) => {
                return { ...prevState, role_SeniorAdmin: true, role_Admin: false, role_User: false, roles: false };
            });
        } else {
            if (role == "user") {
                setSettings((prevState) => {
                    return { ...prevState, role_User: true, role_SeniorAdmin: false, role_Admin: false, roles: true };
                });
            } else if (role == "admin") {
                setSettings((prevState) => {
                    return { ...prevState, role_Admin: true, role_SeniorAdmin: false, role_User: false, roles: true };
                });
            }
        }
    }

    function handleBackToAdd() {
        setSettings((prevState) => {
            return { ...prevState, edit: false, name: "", email: "", roles: true, role_Admin: false, role_User: false, userDepartments: "" };
        });
    }

    function handleUpdate() {
        const departments = ConvertDepartmentsToIDs();

        let role = "";
        if (settings.role_SeniorAdmin) role = "seniorAdmin";
        if (settings.role_User) role = "user";
        if (settings.role_Admin) role = "admin";

        const data = { uuid: settings.editID, name: settings.name, email: settings.email, role: role, departments: departments, orgID: orgID };

        Axios.post("/orgAdmin/users/updateUser", data)
            .then((res) => {
                const data = res.data;

                if (data.message == "User updated successfully") {
                    setModal((prevState) => {
                        return { ...prevState, heading: "Update User", message: "User has been updated", open: true };
                    });

                    setSettings((prevState) => {
                        return {
                            ...prevState,
                            users: data.users,
                            edit: false,
                            name: "",
                            email: "",
                            role_Admin: false,
                            role_User: false,
                            userDepartments: []
                        };
                    });
                    for (const department of settings.departments) {
                        department.active = false;
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function goToUserDetails(uuid) {
        AdminUsersSettings.setGUserID(dispatch, uuid);
        AdminUsersSettings.setFromDetails(dispatch, true);

        navigate("/org/" + orgID + "/userDetails");
    }

    return (
        <div>
            <table width="100%" border="1px">
                <thead>
                    <tr>
                        <th>
                            <div style={OrganisationAdminStyles.headingText}>
                                <Image style={OrganisationAdminStyles.plusImage} src={settings.open ? minus : plus} onClick={openTab} /> Users
                            </div>
                            <br />
                            <Collapse in={settings.open}>
                                <div>
                                    <div style={OrganisationAdminStyles.marginTextHide}>-</div>
                                    <div style={OrganisationAdminStyles.normalText}>
                                        {/* <p>Name</p><p>Email</p><p>Role</p><p>Deparments</p> */}
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col style={UsersStyles.bordered}>
                                                        <strong>User Details:</strong>
                                                        <Form>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label id="lblName">Name:</Form.Label>
                                                                        <Form.Control
                                                                            id="txtName"
                                                                            name="name"
                                                                            type="text"
                                                                            value={settings.name}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label id="lblEmail">Email:</Form.Label>
                                                                        <Form.Control
                                                                            id="txtEmail"
                                                                            name="email"
                                                                            value={settings.email}
                                                                            type="text"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <div style={settings.roles ? UsersStyles.show : UsersStyles.hidden}>
                                                                            <Form.Label id="lblRole">Role:</Form.Label>
                                                                            <Form.Check
                                                                                id="radRoleUser"
                                                                                type="radio"
                                                                                name="role_User"
                                                                                checked={settings.role_User}
                                                                                onChange={handleRadioChange}
                                                                                label="User"
                                                                            />
                                                                            <Form.Check
                                                                                id="radRoleAdmin"
                                                                                type="radio"
                                                                                name="role_Admin"
                                                                                checked={settings.role_Admin}
                                                                                onChange={handleRadioChange}
                                                                                label="Admin"
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label id="lblDepartmentsTitle">Departments:</Form.Label> <br />
                                                                        {settings.userDepartments.length == 0 ? (
                                                                            <div style={UsersStyles.hidden}>
                                                                                <Form.Label>---</Form.Label>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <Form.Label id="lblDepartments">
                                                                                    {settings.userDepartments.toString()}
                                                                                </Form.Label>
                                                                            </div>
                                                                        )}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div style={UsersStyles.submitButtonShow}>
                                                                        {!settings.edit ? (
                                                                            <div>
                                                                                <Button variant="primary" hidden onClick={handleMultipleAdd}>
                                                                                    Add Multiple
                                                                                </Button>
                                                                                <Button variant="primary" onClick={handleSingleAdd}>
                                                                                    Add
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <Button
                                                                                    style={OrganisationAdminStyles.sideBySide}
                                                                                    variant="primary"
                                                                                    onClick={handleBackToAdd}
                                                                                >
                                                                                    Add New User
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row
                                                                style={
                                                                    settings.edit
                                                                        ? UsersStyles.width100
                                                                        : { ...UsersStyles.width100, ...UsersStyles.hidden }
                                                                }
                                                            >
                                                                <Col>
                                                                    <div>
                                                                        <Button
                                                                            style={OrganisationAdminStyles.sideBySide}
                                                                            variant="primary"
                                                                            onClick={handleUpdate}
                                                                        >
                                                                            Update
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col style={UsersStyles.bordered}>
                                                        <strong>Departments:</strong>
                                                        <div style={OrganisationAdminStyles.scrollable_200}>
                                                            <ListGroup>
                                                                {settings.departments.map((department, index) => {
                                                                    return (
                                                                        <ListGroup.Item
                                                                            key={index}
                                                                            action
                                                                            active={department.active}
                                                                            onClick={() => {
                                                                                handleClickedItem_Deparment(department.name);
                                                                            }}
                                                                        >
                                                                            {department.name}
                                                                        </ListGroup.Item>
                                                                    );
                                                                })}
                                                            </ListGroup>
                                                        </div>
                                                        <div style={UsersStyles.addButton}>
                                                            <Button onClick={hanldeRemoveDepFromUser}>Remove</Button>
                                                            <Button onClick={handleAddDepToUser}>Add</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                Users: <br />
                                                Single Click on a user to make quick changes on the left, or Double Click to see Advanced Actions
                                                <div style={OrganisationAdminStyles.scrollable_250}>
                                                    <ListGroup>
                                                        {settings.users.map((user, index) => {
                                                            let name = "";
                                                            if (user.role == "seniorAdmin") {
                                                                name = user.displayName + " (Senior Admin)";
                                                            } else if (user.role == "admin") {
                                                                name = user.displayName + " (Admin)";
                                                            } else {
                                                                name = user.displayName;
                                                            }
                                                            return (
                                                                <ListGroup.Item
                                                                    href={"#" + index}
                                                                    key={index}
                                                                    action
                                                                    onDoubleClick={() => {
                                                                        goToUserDetails(user.uuid);
                                                                    }}
                                                                    onClick={() => {
                                                                        handleClickedItem_User(
                                                                            user.uuid,
                                                                            user.displayName,
                                                                            user.email,
                                                                            user.role,
                                                                            user.departments
                                                                        );
                                                                    }}
                                                                >
                                                                    {name}
                                                                </ListGroup.Item>
                                                            );
                                                        })}
                                                    </ListGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Collapse>
                        </th>
                    </tr>
                </thead>
            </table>

            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={userModal.open} onHide={handleUserModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{userModal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {userModal.message}
                    <br />
                    <br />
                    Password: {userModal.password}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUserModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <br />
        </div>
    );
}

export default Users;
