export const centred = {
    padding: "15px",
    float: "left"
};

export const settings = {
    paddingTop: "15px"
};

export const checkSideBySide = {
    float: "left",
    marginLeft: "10px"
};

export const addButton = {
    float: "right"
};

export const slotHeadings = {
    textAlign: "center"
};

export const moveBtnShow = {
    paddingTop: "15px"
};

export const moveBtnHide = {
    paddingTop: "15px",
    visibility: "hidden"
};

export const breakID = {
    paddingTop: "20px",
    paddingLeft: "10px"
};

export const slotID = {
    padding: "10px"
};

export const breakPadding = {
    paddingTop: "10px"
};

export const colorModal90w = {
    width: "270px"
};
