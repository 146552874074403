import React, { useState } from "react";
import { Image, Collapse, Row, Col, Form, Dropdown, Button, Modal, ListGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as OrganisationActions from "../../../../../../store/actions/organistion";
import * as GlobalVarsActions from "../../../../../../store/actions/globalVars";

//IMAHES
import plus from "../../../images/plus.png";
import minus from "../../../images/minus.png";

//STYLES
import * as OrganisationAdminStyles from "../../../styles/organisationAdmin";
import * as RoomsStyles from "../styles/rooms";

function Rooms(props) {
    const orgID = props.orgID;
    const organisation = useSelector((state) => state.organisation);
    const dispatch = useDispatch();

    const [settings, setSettings] = useState({
        open: false,
        layout: "Layout",
        layoutUUID: 0,
        name: "",
        description: "",
        rooms: [],
        fromShow: false,
        addMode: false,
        editMode: false,
        roomUUID: 0,
        yourLayouts: [],
        weekSystem: false,
        acceptFunc: ""
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    const [weekSystemModal, setWeekSystemModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    const [roomDeleteModal, setRoomDeleteModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleWeekSystemModalClose() {
        setWeekSystemModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleRoomDeleteModalClose() {
        setRoomDeleteModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleWeekSystemModalYes() {
        setSettings((prevState) => {
            return { ...prevState, weekSystem: true };
        });

        setWeekSystemModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleWeekSystemModalNo() {
        setSettings((prevState) => {
            return { ...prevState, weekSystem: false };
        });

        setWeekSystemModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function openTab() {
        if (!settings.open) {
            const data = { orgID: orgID };
            Axios.post("/orgAdmin/layouts/getLayouts", data)
                .then((res) => {
                    setSettings((prevState) => {
                        return { ...prevState, open: true, yourLayouts: res.data.layouts };
                    });
                    checkIfAddRoomsAllowed(organisation.allocatedRooms, organisation.redeemedRooms, res.data.layouts.length);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setSettings((prevState) => {
                return { ...prevState, open: false };
            });
        }

        const data = { orgID: orgID };

        Axios.post("/orgAdmin/rooms/getRooms", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                setSettings((prevState) => {
                    return { ...prevState, rooms: data.rooms };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function checkIfAddRoomsAllowed(allocatedRooms, redeemedRooms, noOfLayouts) {
        console.log(redeemedRooms);
        if (allocatedRooms === redeemedRooms) {
            setSettings((prevState) => {
                return { ...prevState, addMode: false, fromShow: false };
            });
        } else if (noOfLayouts === 0) {
            setSettings((prevState) => {
                return { ...prevState, addMode: false, fromShow: false };
            });
        } else {
            setSettings((prevState) => {
                return { ...prevState, addMode: true, fromShow: true };
            });
        }
    }

    function handleChangeLayout(uuid, layout) {
        setSettings((prevState) => {
            return { ...prevState, layout: layout, layoutUUID: uuid };
        });
    }

    function handleTextChanged(event) {
        const { name, value } = event.target;

        setSettings((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleRoomAdd() {
        if (settings.name === "") {
            setModal((prevState) => {
                return {
                    ...prevState,
                    heading: "Add Room",
                    message: "The room requires a Name",
                    open: true
                };
            });
        } else if (checkNameExsist(settings.name)) {
            setModal((prevState) => {
                return {
                    ...prevState,
                    heading: "Add Room",
                    message: "A room with the same name already exists",
                    open: true
                };
            });
        } else if (settings.layout === "Layout") {
            setModal((prevState) => {
                return {
                    ...prevState,
                    heading: "Add Room",
                    message: "The room requires a Layout",
                    open: true
                };
            });
        } else {
            const data = {
                orgID: orgID,
                name: settings.name,
                details: checkForRoomDetails(),
                description: settings.description,
                layout: settings.layoutUUID,
                weekSystem: settings.weekSystem
            };

            Axios.post("/orgAdmin/rooms/addRoom", data)
                .then((res) => {
                    const data = res.data;

                    if (data.message === "Successfully added room") {
                        dispatch(OrganisationActions.orgUpdateRedeemedRooms(data.redeemedRooms));
                        dispatch(OrganisationActions.orgUpdateRooms(data.rooms));

                        setSettings((prevState) => {
                            return {
                                ...prevState,
                                rooms: data.rooms,
                                name: "",
                                description: "",
                                layout: "layout",
                                weekSystem: false
                            };
                        });

                        checkIfAddRoomsAllowed(organisation.allocatedRooms, data.redeemedRooms, settings.yourLayouts.length);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function checkForRoomDetails() {
        if (settings.description !== "") {
            return "True";
        } else {
            return "False";
        }
    }

    function handleRoomUpdate() {
        const data = {
            orgID: orgID,
            uuid: settings.roomUUID,
            name: settings.name,
            details: checkForRoomDetails(),
            description: settings.description,
            layout: settings.layoutUUID
        };

        Axios.post("/orgAdmin/rooms/updateRoom", data)
            .then((res) => {
                if (res.data.message == "Updated Room") {
                    setSettings((prevState) => {
                        return {
                            ...prevState,
                            rooms: res.data.rooms,
                            roomUUID: 0,
                            editMode: false
                        };
                    });

                    checkIfAddRoomsAllowed(organisation.allocatedRooms, organisation.redeemedRooms, settings.yourLayouts.length);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleAddNewRoom() {
        setSettings((prevState) => {
            return { ...prevState, editMode: false };
        });

        checkIfAddRoomsAllowed(organisation.allocatedRooms, organisation.redeemedRooms, settings.yourLayouts.length);
    }

    function checkNameExsist(name) {
        let check = false;

        for (const room of settings.rooms) {
            if (room.name == name) {
                check = true;
                break;
            }
        }

        return check;
    }

    function handleItemOnClick(uuid, roomName, roomDescrption, layout, layoutUUID, weekSystem) {
        setSettings((prevState) => {
            return {
                ...prevState,
                roomUUID: uuid,
                name: roomName,
                description: roomDescrption,
                fromShow: true,
                editMode: true,
                addMode: false,
                layout: layout,
                layoutUUID: layoutUUID,
                weekSystem: weekSystem == "true"
            };
        });
    }

    function handleRemoveRoom() {
        setRoomDeleteModal((prevState) => {
            return {
                ...prevState,
                heading: "Remove Room",
                message: "Are you sure you want to delete this room and all it's bookings",
                acceptFunc: handleDeleteRoomAccept,
                open: true
            };
        });
    }

    function handleDeleteRoomAccept() {
        setRoomDeleteModal((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { orgID: orgID, roomUUID: settings.roomUUID };

        Axios.post("/orgAdmin/rooms/deleteRoom", data)
            .then((res) => {
                const data = res.data;

                if (data.message === "Successfully deleted room") {
                    dispatch(OrganisationActions.orgUpdateRedeemedRooms(data.redeemedRooms));
                    dispatch(OrganisationActions.orgUpdateRooms(data.rooms));

                    setSettings((prevState) => {
                        return {
                            ...prevState,
                            rooms: data.rooms,
                            name: "",
                            description: "",
                            layout: "layout",
                            weekSystem: false,
                            editMode: false
                        };
                    });

                    checkIfAddRoomsAllowed(organisation.allocatedRooms, data.redeemedRooms, settings.yourLayouts.length);

                    dispatch(GlobalVarsActions.ResetToDefaults());
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getLayoutName(uuid) {
        for (const layout of settings.yourLayouts) {
            if (layout.uuid == uuid) {
                return layout.name;
            }
        }
    }

    function handleWeekSystemClicked(event) {
        const { checked } = event.target;

        if (checked) {
            const data = { orgID: orgID };

            Axios.post("/orgAdmin/rooms/getMainOrgWeekSystem", data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.weekSystem == "false") {
                        setModal({
                            heading: "Week System",
                            message:
                                "You cannot turn on a room week system until you turn on the main week system in the 'Week System / Holidays' tab",
                            open: true
                        });

                        setSettings((prevState) => {
                            return { ...prevState, weekSystem: false };
                        });
                    } else {
                        setSettings((prevState) => {
                            return { ...prevState, weekSystem: checked };
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setSettings((prevState) => {
                return { ...prevState, weekSystem: checked };
            });
        }
    }

    return (
        <div>
            <table width="100%" border="1px">
                <thead>
                    <tr>
                        <th>
                            <div style={OrganisationAdminStyles.headingText}>
                                <Image style={OrganisationAdminStyles.plusImage} src={settings.open ? minus : plus} onClick={openTab} /> Rooms
                            </div>
                            <br />
                            <Collapse in={settings.open}>
                                <div>
                                    <div style={OrganisationAdminStyles.marginTextHide}>-</div>
                                    <div style={OrganisationAdminStyles.normalText}>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <div
                                                        style={
                                                            settings.fromShow
                                                                ? RoomsStyles.roomsAdd
                                                                : { ...RoomsStyles.roomsAdd, ...RoomsStyles.roomsHide }
                                                        }
                                                    >
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Label id="lblName">Name:</Form.Label>
                                                                <Form.Control
                                                                    id="txtName"
                                                                    name="name"
                                                                    value={settings.name}
                                                                    onChange={handleTextChanged}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label id="lblDescription">Descrption:</Form.Label>
                                                                <Form.Control
                                                                    id="txtDescription"
                                                                    name="description"
                                                                    value={settings.description}
                                                                    onChange={handleTextChanged}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                    <div style={settings.fromShow ? RoomsStyles.roomsAdd : RoomsStyles.roomsHide}>
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Label style={OrganisationAdminStyles.sideBySide} id="lblName">
                                                                    Layout:
                                                                </Form.Label>
                                                                <Dropdown style={OrganisationAdminStyles.sideBySide}>
                                                                    <Dropdown.Toggle variant="primary" id="dropdown-layouts">
                                                                        {settings.layout}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu style={OrganisationAdminStyles.dropdownItems}>
                                                                        {settings.yourLayouts.map((layout, index) => {
                                                                            return (
                                                                                <Dropdown.Item
                                                                                    key={index}
                                                                                    onClick={() => {
                                                                                        handleChangeLayout(layout.uuid, layout.name);
                                                                                    }}
                                                                                >
                                                                                    {layout.name}
                                                                                </Dropdown.Item>
                                                                            );
                                                                        })}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                {settings.fromShow && settings.addMode ? (
                                                                    <div style={OrganisationAdminStyles.centred}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            checked={settings.weekSystem}
                                                                            onChange={handleWeekSystemClicked}
                                                                            label="Use week system"
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                                {settings.fromShow && settings.editMode ? (
                                                                    <div style={OrganisationAdminStyles.centred}>
                                                                        Week System: {settings.weekSystem ? " Enabled" : " Disabled"}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </Row>
                                                <Row style={OrganisationAdminStyles.addButton}>
                                                    {settings.fromShow && settings.addMode ? (
                                                        <Button variant="primary" onClick={handleRoomAdd}>
                                                            Add
                                                        </Button>
                                                    ) : null}
                                                    {settings.fromShow && settings.editMode > 0 ? (
                                                        <div>
                                                            {organisation.allocatedRooms !== organisation.redeemedRooms ? (
                                                                <Button variant="primary" onClick={handleAddNewRoom}>
                                                                    Add New Room
                                                                </Button>
                                                            ) : null}
                                                            <Button variant="primary" onClick={handleRoomUpdate}>
                                                                Update
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </Row>
                                                <Row>
                                                    <div
                                                        style={
                                                            settings.yourLayouts.length == 0
                                                                ? RoomsStyles.layoutText
                                                                : { ...RoomsStyles.layoutText, ...RoomsStyles.roomsHide }
                                                        }
                                                    >
                                                        <strong>
                                                            There are no layouts in your system yet, use the layouts tab to add one. Then your will be
                                                            able to assign a room to a layout.
                                                        </strong>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                Allocated Rooms: {organisation.allocatedRooms} <br />
                                                Redeemed Rooms: {organisation.redeemedRooms}
                                            </Col>
                                            <Col>
                                                Room List:
                                                <div style={OrganisationAdminStyles.scrollable_300}>
                                                    <ListGroup>
                                                        {settings.rooms.map((room, index) => {
                                                            return (
                                                                <ListGroup.Item
                                                                    href={"#" + index}
                                                                    variant={settings.roomUUID == room.uuid ? "primary" : null}
                                                                    key={index}
                                                                    action
                                                                    onClick={() => {
                                                                        handleItemOnClick(
                                                                            room.uuid,
                                                                            room.name,
                                                                            room.description,
                                                                            getLayoutName(room.layout),
                                                                            room.layout,
                                                                            room.weekSystem
                                                                        );
                                                                    }}
                                                                >
                                                                    {room.name} ({getLayoutName(room.layout)})
                                                                </ListGroup.Item>
                                                            );
                                                        })}
                                                    </ListGroup>
                                                </div>
                                                <div
                                                    style={
                                                        settings.editMode ? OrganisationAdminStyles.buttonShow : OrganisationAdminStyles.buttonHidden
                                                    }
                                                >
                                                    <Button onClick={handleRemoveRoom}>Remove</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Collapse>
                        </th>
                    </tr>
                </thead>
            </table>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={roomDeleteModal.open} onHide={handleRoomDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{roomDeleteModal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{roomDeleteModal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={roomDeleteModal.acceptFunc}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleRoomDeleteModalClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={weekSystemModal.open} onHide={handleWeekSystemModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Week System</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleWeekSystemModalNo}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleWeekSystemModalYes}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <br />
        </div>
    );
}

export default Rooms;
