import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as BookingDetailsActions from "../../../../../../store/actions/bookings";
import * as RoomDetailsActions from "../../../../../../store/actions/roomDetails";
import * as BookingDataActions from "../../../../../../store/actions/bookingData";

//STYLES
import * as BookingsStyles from "../styles/bookings";

function BookingDetails(props) {
    const user = useSelector((state) => state.user);
    const bookings = useSelector((state) => state.bookings);
    const userProfile = useSelector((state) => state.userProfile);

    const dispatch = useDispatch();

    const [modalDelete, setModalDelete] = useState({
        open: false,
        heading: "",
        message: "",
        repeatBooking: ""
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalDeleteClose() {
        setModalDelete((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    //DELETE BOOKING
    function handleDeleteBooking() {
        if (bookings.bookingType == "Repeat - weekly" || bookings.bookingType == "Repeat - daily") {
            setModalDelete({
                heading: "Repeat Booking",
                message: "This is a repeat booking, do you want to delete the full booking or just this session?",
                repeatBooking: true,
                open: true
            });
        } else {
            setModalDelete({
                heading: "Delete Booking",
                message: "Are you sure you want to delete this booking?",
                repeatBooking: false,
                open: true
            });
        }
    }

    function handleDeleteFullBooking() {
        setModalDelete({ open: false });
        deleteFullBooking();
    }

    function deleteFullBooking() {
        const data = { orgID: props.orgID, bookingID: bookings.bookingID };
        Axios.post("/bookings/deleteBooking", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setModal({ heading: "Delete booking", message: "Booking deleted!", open: true });

                    dispatch(BookingDetailsActions.UpdateBookingDetails(false));
                    dispatch(RoomDetailsActions.UpdateShowSwitch(true));

                    dispatch(BookingDataActions.UpdateData());
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleDeleteSessionBooking() {
        setModalDelete({ open: false });

        const data = { orgID: props.orgID, bookingID: bookings.bookingID, sessionID: bookings.slotId };
        Axios.post("/bookings/deleteSessionBooking", data)
            .then((res) => {
                const data = res.data;

                if (data.error === "null") {
                    if (data.userError === "Yes") {
                        setModalYN({
                            heading: "Repeat Booking",
                            message: data.message,
                            showAccept: true,
                            acceptName: "Yes",
                            acceptFunction: deleteFullBookingAcceptence,
                            showCancel: true,
                            cancelName: "No",
                            open: true
                        });
                    } else {
                        dispatch(BookingDetailsActions.UpdateBookingDetails(false));
                        dispatch(RoomDetailsActions.UpdateShowSwitch(true));

                        dispatch(BookingDataActions.UpdateData());
                    }
                } else {
                    setModal(() => {
                        return { heading: "Booking", message: data.message, open: true };
                    });
                }

                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteFullBookingAcceptence() {
        setModalYN({ open: false });

        deleteFullBooking();
    }

    return (
        <div>
            <strong>Booking Details</strong>
            <div style={BookingsStyles.alignLeft}>
                <br />
                <Row>
                    <Col>
                        <strong>User:</strong>
                    </Col>
                    <Col>{bookings.user}</Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Department:</strong>
                    </Col>
                    <Col>{bookings.department}</Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Class:</strong>
                    </Col>
                    <Col>{bookings.sessionDes}</Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                        <strong>Session Length:</strong>
                    </Col>
                    <Col>
                        <br />
                        {bookings.sessionLength}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <strong>Booking Type:</strong>
                    </Col>
                    <Col>{bookings.bookingType}</Col>
                </Row>
                {bookings.bookingType == "Repeat - weekly" || bookings.bookingType == "Repeat - daily" ? (
                    <div>
                        <Row>
                            <Col>
                                <strong>Repeat Until:</strong>
                            </Col>
                            <Col>{bookings.bookingUntil}</Col>
                        </Row>
                    </div>
                ) : null}
                {bookings.comments != "" ? (
                    <div>
                        <Row>
                            <Col>
                                <br />
                                <strong>Comments</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>{bookings.comments}</Col>
                        </Row>
                    </div>
                ) : null}
                <br /> <br />
                {user.uuid == bookings.createdBy || userProfile.room_Delete ? (
                    <Button variant="primary" onClick={handleDeleteBooking}>
                        Delete
                    </Button>
                ) : null}
            </div>
            <Modal show={modalDelete.open} onHide={handleModalDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalDelete.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalDelete.message}</Modal.Body>
                <Modal.Footer>
                    {modalDelete.repeatBooking ? (
                        <div>
                            <Button variant="primary" onClick={handleDeleteFullBooking}>
                                Full Booking
                            </Button>
                            <Button variant="primary" onClick={handleDeleteSessionBooking}>
                                This Session
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Button variant="primary" onClick={handleDeleteFullBooking}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    )}
                    <Button variant="primary" onClick={handleModalDeleteClose}>
                        {modalDelete.repeatBooking ? "Cancel" : "No"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BookingDetails;
