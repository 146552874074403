import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";

//ACTIONS
import * as UserActions from "../../../../../../store/actions/user";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function CreatePassword() {
    const user = useSelector((state) => state.user);

    const [message, setmessage] = useState("");
    const [passwordHelp, setPasswordHelp] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "STAFF";
    }, []);

    function setPassword(event) {
        event.preventDefault();

        const oldPassword = document.getElementById("txtCurrentPassword").value;
        const newPassword = document.getElementById("txtNewPassword").value;
        const confirmPassword = document.getElementById("txtConfirmPassword").value;

        if (oldPassword != "" && newPassword != "" && confirmPassword != "") {
            const data = { oldPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword, userID: user.uuid };

            Axios.post("/security/createPassword", data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.userError == "Yes") {
                        if (res.data.message == "Your password is not strong enough") {
                            setmessage(res.data.message);
                            setPasswordHelp(true);
                        } else {
                            setPasswordHelp(false);
                            setmessage(res.data.message);
                        }
                    } else {
                        if (res.data.message == "Updated user first password") {
                            dispatch(UserActions.userUpdateNew(true));

                            navigate("/", { replace: true });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>Hello, and welcome to My STAFF</h1>
                    <h3>Please create a new password</h3>
                    <form>
                        <label id="lblCurrentPassword">Current Password:</label>
                        <br />
                        <input id="txtCurrentPassword" type="password" required />
                        <br />
                        <br />
                        <label id="lblNewPassword">New Password:</label>
                        <br />
                        <input id="txtNewPassword" type="password" required />
                        <br /> <br />
                        <label id="lblConfirmPassword">Confirm Password:</label>
                        <br />
                        <input id="txtConfirmPassword" type="password" required />
                        <br /> <br />
                        <p>{message}</p>
                        {passwordHelp ? (
                            <div style={SecurityStyles.passwordHelpText}>
                                Your password must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character. It also requires to be a
                                minimum 8 characters in length
                            </div>
                        ) : null}
                        <Button type="submit" variant="warning" onClick={setPassword}>
                            Set Password
                        </Button>
                        <br />
                        <br />
                    </form>
                    <br />
                </CenterContainer>
            </Container>
        </div>
    );
}

export default CreatePassword;
