import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Container, Dropdown, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//STYLES
import * as AdminStyles from "../../styles/admin";

function OrganisationItem(props) {
    const navigate = useNavigate();

    function handleDoubleClick(orgID) {
        navigate(`/administrator/organisationDetails/${orgID}`);
    }

    return (
        <div>
            <Card body style={AdminStyles.organisationsListBox} onDoubleClick={handleDoubleClick.bind(this, props.orgID)}>
                <Row>
                    <Col>
                        <label>{props.name}</label>
                    </Col>
                    <Col>
                        <label>{props.email}</label>
                    </Col>
                    <Col>
                        <label>{props.poc}</label>
                    </Col>
                    <Col>
                        <label>{props.orgID}</label>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default OrganisationItem;
