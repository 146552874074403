import React from "react";
import { Row, Col } from "react-bootstrap";
import UserNav from "./userNav";

//STYLES
import * as NavBarStyles from "./styles/navBarStyles";

function Nav() {
    return (
        <nav>
            <Row style={NavBarStyles.background}>
                <Col></Col>
                <Col>
                    <a href="/">
                        <div style={NavBarStyles.clickableTitle}>
                            <h1>
                                <strong>My STAFF</strong>
                            </h1>
                            <h2>
                                <strong>Space, Times, Flexible, Facilities</strong>
                            </h2>
                        </div>
                    </a>
                </Col>
                <Col>
                    <div style={NavBarStyles.userNav}>
                        <UserNav />
                    </div>
                </Col>
            </Row>
        </nav>
    );
}

export default Nav;
