export const orgNumberStyle = {
    fontSize: "1.3rem",
    border: "blue",
    borderStyle: "solid",
    padding: "10px",
    width: "100%"
};

export const homeImg = {
    width: "600px",
    height: "400px",
    border: "solid 1px",
    minWidth: "400px",
    maxWidth: "100%",
    maxHeight: "auto"
};

export const imgPadding = {
    padding: "10px"
};

export const blueHeading = {
    color: "blue"
};

export const alignLeft = {
    textAlign: "left"
};

export const text = {
    fontSize: "1.3rem"
};

export const cardsPadding = {
    padding: "10px"
};

export const cardTitle = {
    backgroundColor: "darkblue",
    color: "white",
    padding: "20px"
};

export const card = {
    width: "18rem",
    margin: "0",
    float: "none",
    color: "black"
};

export const mxAuto = {
    fontSize: "1.3rem",
    color: "white"
};
