export const oneOffServicesContainer = {
    border: "2px solid black",
    fontSize: "1.5rem"
};

export const cardStyle = {
    backgroundColor: "#1e56a0",
    color: "white",
    cursor: "pointer"
};

export const spinner = {
    textAlign: "center"
};
