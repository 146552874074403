export const marginText = {
    font: "1rem",
    paddingLeft: "2px",
    width: "9%",
    float: "left",
    textAlign: "center"
};

export const datePadding = {
    paddingTop: "10px"
};

export const holidaysAddNew = {
    width: "100%"
};

export const hide = {
    visibility: "hidden"
};

export const show = {
    visibility: "visible"
};

export const centred = {
    padding: "5px",
    width: "100%"
};

export const rowPadding = {
    paddingTop: "10px"
};

export const width100 = {
    width: "100%"
};

//HELP SECTION
export const help_HolidayTitles = {
    color: "red"
};

export const help_Weeks = {
    color: "blue"
};

export const help_weeksBordered = {
    border: "solid 2px",
    borderColor: "blue"
};

export const help_WeekNums = {
    color: "orange"
};

export const help_holidaytilesBordered = {
    border: "solid 2px",
    borderColor: "red"
};

export const saveButtonHidden = {
    float: "right",
    visibility: "hidden"
};

export const saveButtonShow = {
    float: "right"
};

export const normal = {};
