export const alignRight = {
    textAlign: "right",
    paddingRight: "5%"
};

export const alignLeft = {
    textAlign: "left"
};

export const bulletPiontsSquare = {
    listStyle: "square"
};

export const icon = {
    width: "200px",
    height: "200px"
};
