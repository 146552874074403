import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

//COMPONENTS
import LoginSettings from "./pods/loginSettings/files/loginSettings";
import DepartmentSettings from "./pods/departmentSettings/files/departmentSettings";
import Users from "./pods/users/files/users";
import Rooms from "./pods/rooms/files/rooms";
import Layouts from "./pods/layoutSettings/files/layoutSettings";
import WeekSystemHolidays from "./pods/weekSystemHolidays/files/weekSystemHolidays";
import UserProfiles from "./pods/userProfiles/files/userProfiles";

import CenterContainer from "../../../globalStyles/centerContainer";

//STYLES
import * as OrganisationAdminStyles from "./styles/organisationAdmin";

function OrganisationAdmin() {
    const params = useParams();

    const user = useSelector((state) => state.user);
    const userProfile = useSelector((state) => state.userProfile);

    useEffect(() => {
        document.title = "STAFF";
    }, []);

    return (
        <div style={OrganisationAdminStyles.body}>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    {userProfile.userSettings.administrator.admin_loginSettings || user.role == "seniorAdmin" ? (
                        <LoginSettings orgID={params.orgID} />
                    ) : null}
                    {userProfile.userSettings.administrator.admin_departments || user.role == "seniorAdmin" ? (
                        <DepartmentSettings orgID={params.orgID} />
                    ) : null}
                    {userProfile.userSettings.administrator.admin_users || user.role == "seniorAdmin" ? <Users orgID={params.orgID} /> : null}
                    {userProfile.userSettings.administrator.admin_rooms || user.role == "seniorAdmin" ? <Rooms orgID={params.orgID} /> : null}
                    {userProfile.userSettings.administrator.admin_layouts || user.role == "seniorAdmin" ? <Layouts orgID={params.orgID} /> : null}
                    {userProfile.userSettings.administrator.admin_weekSystemHolidays || user.role == "seniorAdmin" ? (
                        <WeekSystemHolidays orgID={params.orgID} />
                    ) : null}
                    {userProfile.userSettings.administrator.admin_userProfiles || user.role == "seniorAdmin" ? (
                        <UserProfiles orgID={params.orgID} />
                    ) : null}
                </CenterContainer>
            </Container>
        </div>
    );
}

export default OrganisationAdmin;
