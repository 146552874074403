import * as Colors from "../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem",
    textAlign: "center"
};

export const addOrganisationText = {
    fontSize: "1.5rem"
};

export const addOrganisationTextColor = {
    color: "red",
    fontWeight: "bold"
};

export const organisationsListBox = {
    backgroundColor: "#ffbb33",
    color: "white",
    cursor: "pointer"
};

export const organisationsListTitles = {
    backgroundColor: "orange",
    color: "white"
};

export const addOrganisationBtn = {
    textAlign: "right"
};

export const orgDetails_details = {
    fontSize: "1.5rem",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "left"
};

export const orgDetails_detailsTitles = {
    textAlign: "right"
};
