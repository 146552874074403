import userDetails from "./userDetails";
import organisationDetails from "./organisationDetails";
import globalVars from "./globalVars";
import bookings from "./bookings";
import bookingData from "./bookingData";
import roomDetails from "./roomDetails";
import userProfile from "./userProfile";
import preBuiltfeatures from "./preBuiltFeatures";

import HomePageGlobalSettings from "./globalSettings/homePageGlobalSettings";
import AdminLayoutsGlobalSettings from "./globalSettings/adminLayoutsGlobalSettings";
import UserProfileAdminGlobalSettings from "./globalSettings/userProfileAdminGlobalSettings";
import AdminProfileGlobalSettings from "./globalSettings/adminProfileGlobalSettings";
import AdminUserGlobalSettings from "./globalSettings/adminUserGlobalSettings";
import MainAdminGlobalSettings from "./globalSettings/mainAdminGlobalSettings";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
	user: userDetails,
	organisation: organisationDetails,
	globalVars: globalVars,
	bookings: bookings,
	bookingData: bookingData,
	userProfile: userProfile,
	roomDetails: roomDetails,
	preBuiltFeatures: preBuiltfeatures,

	//SETTINGS
	HomePageGlobalSettings: HomePageGlobalSettings,
	AdminLayoutsGlobalSettings: AdminLayoutsGlobalSettings,
	UserProfileAdminGlobalSettings: UserProfileAdminGlobalSettings,
	AdminProfileGlobalSettings: AdminProfileGlobalSettings,
	AdminUserGlobalSettings: AdminUserGlobalSettings,
	MainAdminGlobalSettings: MainAdminGlobalSettings,
});

export default rootReducer;
