import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../store/actions/globalVars";
import * as BookingsActions from "../../../../../store/actions/bookings";
import * as RoomDetailsActions from "../../../../../store/actions/roomDetails";
import * as BookingDataActions from "../../../../../store/actions/bookingData";

//STYLES
import * as TemplatesStyles from "../../styles/pods/templates";

function Dairy(props) {
    const orgID = props.orgID;
    const organisation = useSelector((state) => state.organisation);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);
    const bookings = useSelector((state) => state.bookings);
    const bookingData = useSelector((state) => state.bookingData);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [settings, setSettings] = useState({
        slotClass: "",
        dayClass: "",
        times: [],
        startTime: "",
        finishTime: "",
        interval: "",
        dates: ["", "", "", "", "", "", ""],
        dayIndex: 0,
        holiday: "",
        days: [],
        weekSystemUUID: "",
        currentDay: "",
        pastWeek: false
    });

    useEffect(() => {
        setup();
    }, [props.roomName]);

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function setup() {
        const layoutDays = props.layoutData.days.split(",");
        const days = [];

        const startTime = props.layoutData.startTime;
        const finishTime = props.layoutData.finishTime;
        const timeInterval = props.layoutData.timeInterval;

        for (const day of layoutDays) {
            days.push(day == "true");
        }

        let totalDays = 0;
        for (const day of days) {
            if (day) {
                totalDays++;
            }
        }

        setSettings((prevState) => {
            return {
                ...prevState,
                slotClass: "session" + totalDays + "_slot",
                dayClass: "session" + totalDays + "_days",
                startTime: startTime,
                finishTime: finishTime,
                interval: timeInterval,
                days: days
            };
        });

        const times = BuildTimes(startTime, finishTime, timeInterval);

        if (globalVars.roomDetail.weekBeginDate == "") {
            const weekBG = FindWeekBegin(days);
            BuildDataSlots(weekBG, times);
            CheckHoliday(weekBG);
        } else {
            FindDayIndex(days);
            const firstDate = moment(globalVars.roomDetail.weekBeginDate, "DD/MM/YYYY");
            RebuildDates(firstDate, times);
        }
    }

    function FindDayIndex(days) {
        let dayIndex;
        for (const [index, day] of days.entries()) {
            if (day) {
                dayIndex = index;
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, dayIndex: dayIndex };
        });
    }

    function FindWeekBegin(days) {
        let today = moment();
        const currentDay = today.format("DD/MM/YYYY");
        today.startOf("week");

        let dates = [];
        for (let i = 0; i < 7; i++) {
            dates.push(today.format("DD/MM/YYYY"));
            today.add(1, "d");
        }

        setSettings((prevState) => {
            return { ...prevState, dates: dates, currentDay: currentDay };
        });

        let dayIndex;
        for (const [index, day] of days.entries()) {
            if (day) {
                dayIndex = index;
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, weekbegin: dates[0], dayIndex: dayIndex };
        });

        return dates[0];
    }

    function CheckHoliday(weekBG) {
        const date = moment(weekBG, "DD/MM/YYYY");
        const week = formatString(date.week()) + "-" + date.format("YY");
        let holidayTitle = "";

        for (const holiday of organisation.holidays) {
            if (holiday.weeks.includes(week)) {
                if (holiday.titleUUID.includes("w")) {
                    if (props.weekSystem) {
                        holidayTitle = holiday.name;
                        setSettings((prevState) => {
                            return { ...prevState, weekSystemUUID: holiday.titleUUID };
                        });
                    }
                } else if (holiday.titleUUID.includes("h")) {
                    holidayTitle = "Holiday: " + holiday.name;
                }
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, holiday: holidayTitle };
        });
    }

    function BuildDataSlots(weekBg, times) {
        const data = {};
        const days = [];
        const weekBG = moment(weekBg, "DD/MM/YYYY");
        const slot = { uuid: "", user: "", department: "", type: "" };

        const weekSlot = formatString(weekBG.week()) + "-" + weekBG.format("YY");

        for (let i = 0; i < 7; i++) {
            for (const time of times) {
                days.push(formatString(weekBG.day()) + "-" + time.replace(":", ""));
                const sName =
                    formatString(weekBG.week()) + "-" + weekBG.format("YY") + "-" + formatString(weekBG.day()) + "-" + time.replace(":", "");
                data[sName] = slot;
            }

            weekBG.add(1, "d");
        }

        dispatch(BookingDataActions.GetData(orgID, props.roomID, weekSlot, days, weekBg));
    }

    function formatString(time) {
        if (time.toString().includes("b")) {
            time = time.replace("b", "");

            if (time.toString().length == 1) {
                return "b0" + time;
            } else {
                return time;
            }
        } else {
            if (time.toString().length == 1) {
                return "0" + time;
            } else {
                return time;
            }
        }
    }

    function BuildTimes(sTime, fTime, interval) {
        const ti = [];
        if (sTime !== "Start Time" && fTime !== "Finish Time" && interval !== 0) {
            const start = moment(sTime, "HH:mm");
            const finish = moment(fTime, "HH:mm");

            while (start.isBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(interval, "m");
            }

            setSettings((prevState) => {
                return { ...prevState, times: ti };
            });
        }

        return ti;
    }

    function RebuildDates(firstDate, times) {
        let dates = [];
        for (let i = 0; i < 7; i++) {
            dates.push(firstDate.format("DD/MM/YYYY"));
            firstDate.add(1, "d");
        }

        //Checks if the color is black (present) or red (past)
        let currentWeek = moment();
        currentWeek.startOf("week");

        let past = false;
        if (moment(dates[0], "DD/MM/YYYY") < currentWeek) {
            past = true;
        }
        ///

        setSettings((prevState) => {
            return { ...prevState, dates: dates, pastWeek: past };
        });

        BuildDataSlots(dates[0], times);
        CheckHoliday(dates[0]);
    }

    function roomDetailsResetHandler() {
        dispatch(RoomDetailsActions.UpdateShowSwitch(true));
        dispatch(BookingsActions.UpdateBookingID(0));
    }

    function handleAdvancedWeek() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        roomDetailsResetHandler();
        let newDate = moment(settings.dates[0], "DD/MM/YYYY");
        newDate.add(1, "w");

        RebuildDates(newDate, settings.times);
    }

    function handleGoBackWeek() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        roomDetailsResetHandler();

        let newDate = moment(settings.dates[0], "DD/MM/YYYY");
        newDate.subtract(1, "w");

        let pastDate = moment();
        pastDate.startOf("week");

        pastDate.subtract(parseInt(userProfile.userSettings.weeks.goBackWeeks), "weeks");

        if (newDate < pastDate) {
            setModal({ heading: props.roomName, message: "You cannot go back previous from this week", open: true });
        } else {
            RebuildDates(newDate, settings.times);
        }
    }

    function handleBookClick(event) {
        if (!organisation.locked && userProfile.room_Write) {
            const { id } = event.target;
            console.log(id);
            const IDs = id.toString().split("-");
            dispatch(GlobalVarsActions.UpdateRoomName(props.roomName));
            dispatch(GlobalVarsActions.UpdateRoomID(props.roomID));
            dispatch(GlobalVarsActions.UpdateRoomLayoutData(props.layoutData));
            dispatch(GlobalVarsActions.UpdateRoomSessionID(id));
            dispatch(GlobalVarsActions.UpdateRoomSessionLabel(IDs[1]));
            dispatch(GlobalVarsActions.UpdateRoomWeekBegin(settings.dates[0]));
            dispatch(GlobalVarsActions.UpdateRoomDate(settings.dates[IDs[0]]));
            dispatch(GlobalVarsActions.UpdateRoomDayList(settings.days));
            dispatch(GlobalVarsActions.UpdateRoomWeekSystem(props.weekSystem));
            dispatch(GlobalVarsActions.UpdateRoomWeekUUID(settings.weekSystemUUID));
            dispatch(GlobalVarsActions.UpdateDiaryStartTime(settings.startTime));
            dispatch(GlobalVarsActions.UpdateDiaryFinishTime(settings.finishTime));
            dispatch(GlobalVarsActions.UpdateDiaryTimeInterval(settings.interval));

            navigate("/org/" + orgID + "/book");
        }
    }

    function GetDepartment(id) {
        for (const department of organisation.departments) {
            if (department.uuid == id) {
                return department.name;
            }
        }
    }

    function handleBookingDetails(uuid, slotId) {
        if (bookings.bookingID == uuid) {
            dispatch(BookingsActions.UpdateBookingDetails(false));
            dispatch(RoomDetailsActions.UpdateShowSwitch(true));
            dispatch(BookingsActions.UpdateBookingID(0));
            return;
        }

        const data = { orgID: orgID, uuid: uuid };
        Axios.post("/bookings/getBookingData", data)
            .then((res) => {
                const data = res.data;
                dispatch(BookingsActions.UpdateBookingUser(data.booking.user));
                dispatch(BookingsActions.UpdateBookingDepartment(GetDepartment(data.booking.departmentID)));
                dispatch(BookingsActions.UpdateBookingSessionDes(data.booking.sessionDes));
                dispatch(BookingsActions.UpdateBookingSessionLength(data.booking.sessionTotal));

                //SINGLE / REPEAT
                const type = data.booking.bookingType;
                if (type == "single") {
                    dispatch(BookingsActions.UpdateBookingBookingType("Single"));
                } else {
                    dispatch(BookingsActions.UpdateBookingBookingType("Repeat - " + data.booking.repeatType));
                    dispatch(BookingsActions.UpdateBookingBookingUntil(data.booking.repeatUntil));
                }

                dispatch(BookingsActions.UpdateBookingComments(data.booking.comments));
                dispatch(BookingsActions.UpdateBookingCreatedBy(data.booking.createdBy));
                dispatch(BookingsActions.UpdateBookingDetails(true));
                dispatch(RoomDetailsActions.UpdateShowSwitch(false));
                dispatch(BookingsActions.UpdateBookingID(uuid));

                const date = moment(settings.dates[0], "DD/MM/YYYY");
                const week = formatString(date.week()) + "-" + date.format("YY");
                dispatch(BookingsActions.UpdateSlotID(week + "-" + slotId));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <Row>
                <Col>
                    <strong>
                        <div style={settings.pastWeek ? TemplatesStyles.centredWbRed : TemplatesStyles.centredWb}>
                            Week Beginning: {settings.dates[settings.dayIndex]}
                        </div>
                    </strong>
                </Col>
                <Col>
                    <strong>
                        <div style={TemplatesStyles.centered100}>{settings.holiday}</div>
                    </strong>
                </Col>
                <Col>
                    <div style={TemplatesStyles.sideBySideR}>
                        <Button variant="primary" onClick={handleGoBackWeek}>
                            -
                        </Button>
                        <Button variant="primary" onClick={handleAdvancedWeek}>
                            +
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <table style={TemplatesStyles.layout} width="100%" border="1px">
                    <thead>
                        <tr>
                            <td style={{ ...TemplatesStyles[settings.slotClass], ...TemplatesStyles.layout }}>Session</td>
                            {settings.days.map((day, index) => {
                                if (day) {
                                    return (
                                        <td
                                            style={
                                                settings.currentDay === settings.dates[index]
                                                    ? {
                                                          ...TemplatesStyles[settings.dayClass],
                                                          ...TemplatesStyles.layout,
                                                          ...TemplatesStyles.currentDay
                                                      }
                                                    : { ...TemplatesStyles[settings.dayClass], ...TemplatesStyles.layout }
                                            }
                                            key={index}
                                        >
                                            {dayNames[index]} <br /> {settings.dates[index]}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {settings.times.map((time, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ ...TemplatesStyles[settings.slotClass], ...TemplatesStyles.layout }}>{time}</td>
                                    {settings.days.map((day, index) => {
                                        if (day) {
                                            if (Object.keys(bookingData.data).length > 0) {
                                                //console.log(Object.keys(bookingData).length);
                                                const name = bookingData.week + "-" + formatString(index) + "-" + time.replace(":", "");
                                                //console.log(name);
                                                //console.log(bookingData);
                                                if (bookingData.data[name].type == "single") {
                                                    return (
                                                        <td
                                                            style={{ ...TemplatesStyles.layout, ...TemplatesStyles.singleSlot }}
                                                            key={index}
                                                            id={index + "-" + time}
                                                            onClick={() =>
                                                                handleBookingDetails(
                                                                    bookingData.data[name].uuid,
                                                                    formatString(index) + "-" + time.replace(":", "")
                                                                )
                                                            }
                                                        >
                                                            {bookingData.data[name].user} <br /> {GetDepartment(bookingData.data[name].department)}
                                                        </td>
                                                    );
                                                } else if (bookingData.data[name].type == "repeat") {
                                                    return (
                                                        <td
                                                            style={{ ...TemplatesStyles.layout, ...TemplatesStyles.repeatSlot }}
                                                            key={index}
                                                            id={index + "-" + time}
                                                            onClick={() =>
                                                                handleBookingDetails(
                                                                    bookingData.data[name].uuid,
                                                                    formatString(index) + "-" + time.replace(":", "")
                                                                )
                                                            }
                                                        >
                                                            {bookingData.data[name].user} <br /> {GetDepartment(bookingData.data[name].department)}
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td
                                                            className={
                                                                !organisation.locked && userProfile.room_Write
                                                                    ? "custom_emptySlot"
                                                                    : "custom_emptySlotDisabled"
                                                            }
                                                            style={TemplatesStyles.layout}
                                                            key={index}
                                                            id={index + "-" + time}
                                                            onClick={handleBookClick}
                                                        >
                                                            Book
                                                        </td>
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <td
                                                        className={
                                                            !organisation.locked && userProfile.room_Write
                                                                ? "custom_emptySlot"
                                                                : "custom_emptySlotDisabled"
                                                        }
                                                        style={TemplatesStyles.layout}
                                                        key={index}
                                                        id={index + "-" + time}
                                                        onClick={handleBookClick}
                                                    >
                                                        Book
                                                    </td>
                                                );
                                            }
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Row>

            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Dairy;
