import React from "react";
import { Row, Col } from "react-bootstrap";

function CenterContainer(props) {
    return (
        <div>
            <Row>
                <Col sm={props.ends == null ? 2 : props.ends}></Col>
                <Col md={props.middle == null ? 8 : props.middle} style={{ backgroundColor: '#c2e0af', padding: '20px', height: '100%' }}>
                    {props.children}
                </Col>
                <Col sm={props.ends == null ? 2 : props.ends}></Col>
            </Row>
        </div>
    );
}

export default CenterContainer;
