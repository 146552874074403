import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, ListGroup, Modal, Button, Collapse, Form, Image } from "react-bootstrap";

//ACTIONS
import * as OrganisationActions from "../../../../../../store/actions/organistion";

//IMAGES
import plus from "../../../images/plus.png";
import minus from "../../../images/minus.png";

//STYLES
import * as OrganisationAdminStyles from "../../../styles/organisationAdmin";

function DepartmentSettings(props) {
    const orgID = props.orgID;

    const organisation = useSelector((state) => state.organisation);
    const dispatch = useDispatch();

    const [remove, setRemove] = useState({
        uuid: 0,
        activeRemove: false
    });

    const [departments, setDepartments] = useState({
        open: false,
        saved: false,
        showSave: false,
        useDepartments: false,
        aname: "",
        names: []
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function openDepartments() {
        if (!departments.open) {
            setDepartments((prevState) => {
                return { ...prevState, useDepartments: organisation.useDepartments, names: organisation.departments, open: true };
            });
        } else {
            setDepartments((prevState) => {
                return { ...prevState, open: false };
            });
        }
    }

    function handleChecked(event) {
        const { name, checked } = event.target;
        console.log(checked);

        setDepartments((prevState) => {
            return { ...prevState, [name]: checked, showSave: true };
        });

        const data = { useDepartments: checked.toString(), orgID: orgID };

        Axios.post("/orgAdmin/departmentSettings/updateUseDepartments", data)
            .then((res) => {
                console.log(res.data.useDepartments);

                dispatch(OrganisationActions.orgUpdateUseDepartments(departments.useDepartments));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleChanged(event) {
        const { name, value } = event.target;

        setDepartments((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleAddDepartment() {
        if (departments.aname == "") {
            setModal((prevState) => {
                return { ...prevState, heading: "Add Deparment", message: "Please enter a name", open: true };
            });
        } else {
            if (CheckDepartment(departments.aname)) {
                setModal((prevState) => {
                    return { ...prevState, heading: "Add Deparment", message: "There is already a department with this name", open: true };
                });
            } else {
                const data = { orgID: orgID, departmentName: departments.aname };

                Axios.post("/orgAdmin/departmentSettings/addDepartment", data)
                    .then((res) => {
                        console.log(res.data);
                        dispatch(OrganisationActions.orgUpdateNoOfDepartments(res.data.noOfDepartments));
                        dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));

                        setDepartments((prevState) => {
                            return { ...prevState, names: res.data.departments, aname: "" };
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    function CheckDepartment(name) {
        let check = false;

        for (const department of organisation.departments) {
            if (department.name == name) {
                check = true;
                break;
            }
        }

        return check;
    }

    function handleRemoveDepartment() {
        if (remove.activeRemove) {
            const data = { orgID: orgID, uuid: remove.uuid };

            Axios.post("/orgAdmin/departmentSettings/removeDepartment", data)
                .then((res) => {
                    console.log(res.data);
                    dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));

                    setDepartments((prevState) => {
                        return { ...prevState, names: res.data.departments };
                    });

                    setRemove((preState) => {
                        return { ...preState, activeRemove: false };
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleItemOnClick(uuid) {
        setRemove((prevState) => {
            return { ...prevState, uuid: uuid, activeRemove: true };
        });

        console.log(uuid);
    }

    return (
        <div>
            <table width="100%" border="1px">
                <thead>
                    <tr>
                        <th>
                            <div style={OrganisationAdminStyles.headingText}>
                                <Image style={OrganisationAdminStyles.plusImage} src={departments.open ? minus : plus} onClick={openDepartments} />{" "}
                                Departments
                            </div>
                            <br />
                            <Collapse in={departments.open}>
                                <div>
                                    <div style={OrganisationAdminStyles.marginTextHide}>-</div>
                                    <div style={OrganisationAdminStyles.normalText}>
                                        <Row>
                                            <Col>
                                                <Form>
                                                    <Form.Check
                                                        id="chkUseDepartments"
                                                        name="useDepartments"
                                                        label="Use Departments"
                                                        checked={departments.useDepartments}
                                                        onChange={handleChecked}
                                                    />
                                                    <br />
                                                    <p>Add new department:</p>
                                                    <Form.Control type="text" value={departments.aname} name="aname" onChange={handleChanged} />
                                                    <div style={OrganisationAdminStyles.addButton}>
                                                        <Button onClick={handleAddDepartment}>Add</Button>
                                                    </div>
                                                </Form>
                                            </Col>
                                            <Col>
                                                Departments:
                                                <div style={OrganisationAdminStyles.scrollable_300}>
                                                    <ListGroup>
                                                        {departments.names.map((department, index) => {
                                                            return (
                                                                <ListGroup.Item
                                                                    href={"#" + index}
                                                                    key={index}
                                                                    action
                                                                    onClick={() => {
                                                                        handleItemOnClick(department.uuid);
                                                                    }}
                                                                >
                                                                    {department.name}
                                                                </ListGroup.Item>
                                                            );
                                                        })}
                                                    </ListGroup>
                                                </div>
                                                <div
                                                    style={
                                                        remove.activeRemove
                                                            ? OrganisationAdminStyles.buttonShow
                                                            : OrganisationAdminStyles.buttonHidden
                                                    }
                                                >
                                                    <Button onClick={handleRemoveDepartment}>Remove</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Collapse>
                        </th>
                    </tr>
                </thead>
            </table>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <br />
        </div>
    );
}

export default DepartmentSettings;
