export const content = {
    textAlign: "left"
};

export const topInfo = {
    textAlign: "center",
    paddingBottom: "10px"
};

export const checkSideBySide = {
    float: "left",
    marginLeft: "10px"
};

export const checkSideBySide_b = {
    float: "left",
    marginLeft: "15px"
};

export const addButton = {
    float: "right"
};

export const alignLeft = {
    textAlign: "left"
};

export const bookBtn = {
    textAlign: "right"
};

export const errorText_Show = {
    color: "red",
    textAlign: "right",
    visibility: "visible"
};

export const errorText_Hidden = {
    color: "red",
    textAlign: "right",
    visibility: "hidden"
};
