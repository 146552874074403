import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Dropdown, Button, Modal, Container, Form } from "react-bootstrap";

//NEW STRUCTURE
import YourBookings from "./pods/bookings/files/yourBookings/yourBookings";
import BookingDetails from "./pods/bookings/files/bookingDetails";
import RoomDetails from "./pods/bookings/files/roomDetails";
import Timetable from "./pods/templates/timetable";
import Diary from "./pods/templates/diary";

//COMPONENTS
import CenterContainer from "../../../globalStyles/centerContainer";

//STYLES
import * as OrganisationHomeStyles from "./styles/organisationHome";

//ACTIONS
import * as BookingActions from "../../../store/actions/bookings";
import * as RoomDetailsActions from "../../../store/actions/roomDetails";
import * as UserProfileActions from "../../../store/actions/userProfile";
import * as BookingDataActions from "../../../store/actions/bookingData";

function OrganisationHome(props) {
    const params = useParams();

    const organisation = useSelector((state) => state.organisation);
    const globalVars = useSelector((state) => state.globalVars);
    const bookings = useSelector((state) => state.bookings);
    const roomDetails = useSelector((state) => state.roomDetails);
    const user = useSelector((state) => state.user);
    const userProfile = useSelector((state) => state.userProfile);
    const preBuiltFeatures = useSelector((state) => state.preBuiltFeatures);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [settings, setSettings] = useState({
        orgTitle: "",
        orgLocked: "",
        roomName: "Rooms",
        roomLayout: "",
        roomID: "",
        layoutData: {},
        view: false,
        weekSystem: false,
        section: "Rooms"
    });

    const [features, setFeatures] = useState({
        yourBookings: true
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [filters, setFilters] = useState({
        user: { column: "user", filter: user.uuid, name: true },
        createdBy: { column: "createdBy", filter: "no filter", name: false }
    });

    function handleFilterSelected(filterID) {
        const newFilters = { ...filters };
        if (newFilters[filterID].name) {
            newFilters[filterID].filter = "no filter";
            newFilters[filterID].name = false;
        } else {
            newFilters[filterID].filter = user.uuid;
            newFilters[filterID].name = true;
        }

        setFilters(newFilters);
    }

    useEffect(() => {
        document.title = "STAFF";
        if (!user.auth) {
            navigate.replace("/", { replace: true });
        }
        checkFeatures();
        CheckPreviousState();
    }, []);

    function checkFeatures() {
        setFeatures((prevState) => {
            return {
                ...prevState,
                yourBookings: preBuiltFeatures.find((feature) => feature.name === "yourBookings").enabled === "true"
            };
        });
    }

    function CheckPreviousState() {
        if (globalVars.roomDetail.roomName != "") {
            setSettings((prevState) => {
                return {
                    ...prevState,
                    roomName: globalVars.roomDetail.roomName,
                    roomLayout: globalVars.roomDetail.layoutData.layout,
                    layoutData: globalVars.roomDetail.layoutData,
                    roomID: globalVars.roomDetail.roomID,
                    weekSystem: globalVars.roomDetail.weekSystem
                };
            });
            dispatch(RoomDetailsActions.UpdateShowSwitch(true));
            setTimeout(() => {
                setSettings((prevState) => {
                    return { ...prevState, view: true };
                });
            }, 100);
        }
    }

    function handleRoomDropdownClick(name, layoutUUID, id, weekSystem) {
        setSettings((prevState) => {
            return { ...prevState, roomName: name, roomLayout: "---", roomID: id, weekSystem: false };
        });

        dispatch(BookingActions.UpdateBookingID(0));
        dispatch(RoomDetailsActions.UpdateId(id));
        dispatch(RoomDetailsActions.UpdateShowSwitch(true));

        let layoutData;
        for (const layout of organisation.layouts) {
            if (layout.uuid == layoutUUID) {
                layoutData = layout;
            }
        }
        console.log(layoutData);

        setSettings((prevState) => {
            return {
                ...prevState,
                roomName: name,
                roomLayout: layoutData.layout,
                roomID: id,
                test: "hi",
                weekSystem: weekSystem == "true",
                layoutData: layoutData,
                view: true
            };
        });
        //USER PROFILE
        if (!userProfile.default) {
            UpdateRoomProfileSettings(id);
        }

        dispatch(BookingActions.UpdateBookingDetails(false));
        dispatch(BookingDataActions.SetDataSlots({}));
        setTimeout(() => {
            setSettings((prevState) => {
                return { ...prevState, view: true };
            });
        }, 1000);
    }

    function UpdateRoomProfileSettings(roomUUID) {
        for (const room in userProfile.rooms) {
            if (userProfile.rooms[room].roomUUID == roomUUID) {
                dispatch(UserProfileActions.UpdateRoom_View(userProfile.rooms[room].prop_View == "true"));
                dispatch(UserProfileActions.UpdateRoom_Write(userProfile.rooms[room].prop_Write == "true"));
                dispatch(UserProfileActions.UpdateRoom_Edit(userProfile.rooms[room].prop_Edit == "true"));
                dispatch(UserProfileActions.UpdateRoom_Delete(userProfile.rooms[room].prop_Delete == "true"));
                dispatch(UserProfileActions.UpdateRoom_Repeat(userProfile.rooms[room].prop_Repeat == "true"));
            }
        }
    }

    function changeRoomSection(section) {
        setSettings((prevState) => {
            return { ...prevState, section: section };
        });
    }

    ////////////////////////////////////////////////////////

    return (
        <div style={OrganisationHomeStyles.body}>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>{organisation.name}</h1>
                    {organisation.locked ? <h2>Organisation Locked</h2> : null}
                    <Row>
                        <Col xs={2}>
                            {/* View All <br />
                            <Button style={{ fontSize: "12px" }}>Timetable Rooms</Button>
                            <Button>Diary Rooms</Button> */}
                        </Col>
                        <Col>
                            {settings.section === "Rooms" && (
                                <div>
                                    <div style={OrganisationHomeStyles.roomLbl}>Rooms:</div>
                                    {organisation.rooms.length == 0 ? (
                                        <div style={OrganisationHomeStyles.noRoomsLbl}>No Rooms</div>
                                    ) : (
                                        <div>
                                            <Dropdown style={OrganisationHomeStyles.rooms}>
                                                <Dropdown.Toggle variant="primary" id="dropdown-layouts">
                                                    {settings.roomName}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={OrganisationHomeStyles.dropdownItems}>
                                                    {organisation.rooms.map((room, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={() => {
                                                                    handleRoomDropdownClick(room.name, room.layout, room.uuid, room.weekSystem);
                                                                }}
                                                            >
                                                                {room.name}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )}
                                </div>
                            )}
                            {settings.section == "Your Bookings" && (
                                <div>
                                    <Row>
                                        <Col>
                                            <div style={OrganisationHomeStyles.filterSetting} onClick={handleFilterSelected.bind(this, "user")}>
                                                {filters.user.name ? (
                                                    <i className="fa-regular fa-square-check"></i>
                                                ) : (
                                                    <i className="fa-regular fa-square"></i>
                                                )}{" "}
                                                Assigned to me
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={OrganisationHomeStyles.filterSetting} onClick={handleFilterSelected.bind(this, "createdBy")}>
                                                {filters.createdBy.name ? (
                                                    <i className="fa-regular fa-square-check"></i>
                                                ) : (
                                                    <i className="fa-regular fa-square"></i>
                                                )}{" "}
                                                Created by me
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                        <Col xs={3} style={OrganisationHomeStyles.topRightMenu}>
                            {features.yourBookings ? (
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle>{settings.section}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => changeRoomSection("Rooms")}>Rooms</Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeRoomSection("Your Bookings")}>Your Bookings</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        {settings.section === "Rooms" && (
                            <>
                                <Col></Col>
                                <Col xs={8}>
                                    {settings.view ? (
                                        <div>
                                            {settings.roomLayout != "" && settings.roomLayout == "Timetable" ? (
                                                <Timetable
                                                    orgID={params.orgID}
                                                    roomName={settings.roomName}
                                                    roomID={settings.roomID}
                                                    layoutData={settings.layoutData}
                                                    weekSystem={settings.weekSystem}
                                                />
                                            ) : null}
                                            {settings.roomLayout != "" && settings.roomLayout == "Diary" ? (
                                                <Diary
                                                    orgID={params.orgID}
                                                    roomName={settings.roomName}
                                                    roomID={settings.roomID}
                                                    layoutData={settings.layoutData}
                                                    weekSystem={settings.weekSystem}
                                                />
                                            ) : null}
                                        </div>
                                    ) : null}
                                </Col>
                                <Col>
                                    <Row>
                                        <div style={OrganisationHomeStyles.rowNothing}>
                                            <Button disabled>Test</Button>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {bookings.bookingDetails && <BookingDetails orgID={params.orgID} />}
                                            {roomDetails.show && <RoomDetails />}
                                            <div></div>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                        {settings.section === "Your Bookings" && (
                            <>
                                <Col>
                                    <YourBookings filters={filters} />
                                </Col>
                            </>
                        )}
                    </Row>
                </CenterContainer>
            </Container>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default OrganisationHome;
