import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, Form, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import "react-datepicker/dist/react-datepicker.css";
import * as BookingsStyles from "../styles/bookings";
import * as OrganisationHomeStyles from "../../../styles/organisationHome";

function Book() {
    const navigate = useNavigate();
    const params = useParams();

    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);
    const organisation = useSelector((state) => state.organisation);

    const [formData, setFormData] = useState({
        values: {
            user: { uuid: "", name: "" },
            department: { uuid: "", name: "" },
            sessionDes: "",
            comments: "",
            sessionLength: "",
            repeat: false,
            repeatType: "",
            repeatUntil: moment()
        },
        valids: {
            user: false,
            department: false,
            sessionDes: false,
            repeat: true,
            sessionLength: false,
            repeatUntil: true
        },
        wholeFormValid: false,
        errorOnForm: false,
        dateErrorMessage: "null"
    });

    const [settings, setSettings] = useState({
        users: [],
        showUserDropdown: false,
        departments: [],
        showDepartmentDropdown: false,
        sessionDescriptionTitle: "",
        sessions: [],
        showSessionDropdown: false
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [errorMsg, setErrorMsg] = useState({
        open: false
    });

    function handleErrorMsgClose() {
        setErrorMsg((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        if (globalVars.roomDetail.date == "") {
            navigate(-1);
        } else {
            LoadData({ ...formData.values }, { ...settings });
        }
    }, []);

    function LoadData(values, settings) {
        if (globalVars.roomDetail.layoutData.layout == "Timetable") {
            settings.sessionDescriptionTitle = "Class";
            if (globalVars.roomDetail.sessionID.includes("b")) {
                settings.showSessionDropdown = false;
                values.sessionLength = "1";
            } else {
                const IDs = globalVars.roomDetail.sessionID.split("-");
                const firstIndex = parseInt(IDs[1]);

                let sessions = [];
                let sess = 1;
                for (let i = firstIndex; i <= globalVars.roomDetail.totalSessions; i++) {
                    sessions.push(sess);
                    sess++;
                }

                settings.showSessionDropdown = true;
                settings.sessions = sessions;
                values.sessionLength = "1";
            }
        } else if (globalVars.roomDetail.layoutData.layout == "Diary") {
            settings.sessionDescriptionTitle = "Subject";

            const sTime = moment(globalVars.roomDetail.sessionLabel, "HH:mm");

            const ti = [];

            const start = moment(sTime, "HH:mm");
            const finish = moment(globalVars.roomDetail.finishTime, "HH:mm");

            start.add(globalVars.roomDetail.timeInterval, "m");
            values.sessionLength = start.format("HH:mm");

            while (start.isSameOrBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(globalVars.roomDetail.timeInterval, "m");
            }

            settings.showSessionDropdown = true;
            settings.sessions = ti;
        }
        FetchUsersAndDepartments(values, settings);
    }

    function FetchUsersAndDepartments(values, settings) {
        const data = {
            orgID: params.orgID,
            users: userProfile.userSettings.bookings.viewAllUsers,
            departments: userProfile.userSettings.bookings.viewAllDepartments
        };
        Axios.post("/bookings/usersAndDepartments", data)
            .then((res) => {
                const data = res.data;
                let updateDepartments = false;
                if (data.userList == "none") {
                    settings.showUserDropdown = false;
                    values.user = { uuid: user.uuid, name: user.name };
                } else {
                    settings.showUserDropdown = true;
                    settings.users = data.userList;
                    values.user = data.userList.find((use) => use.uuid == user.uuid);
                }
                if (data.departmentList == "none") {
                    const splitDeparments = user.userDepartments.split(",");
                    if (splitDeparments.length == 1) {
                        (settings.showDepartmentDropdown = false),
                            (values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]));
                    } else {
                        const comboDepartments = [];
                        for (const department of splitDeparments) {
                            const depart = organisation.departments.find((depart) => depart.uuid == department);
                            if (depart != null) {
                                comboDepartments.push(depart);
                            } else {
                                updateDepartments = true;
                            }
                        }
                        settings.showDepartmentDropdown = true;
                        settings.departments = comboDepartments;
                        values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]);
                    }
                } else {
                    settings.showDepartmentDropdown = true;
                    settings.departments = data.departmentList;

                    const splitDeparments = user.userDepartments.split(",");
                    values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]);
                }

                if (updateDepartments) {
                    let departmentsUpdated = "";
                    const splitDeparments = user.userDepartments.split(",");
                    for (const department of splitDeparments) {
                        const depart = organisation.departments.find((depart) => depart.uuid == department);
                        if (depart != null) {
                            if (departmentsUpdated == "") {
                                departmentsUpdated = department;
                            } else {
                                departmentsUpdated = `${departmentsUpdated},${department}`;
                            }
                        }
                    }

                    const data = { uuid: user.uuid, departments: departmentsUpdated };
                    Axios.post("/orgAdmin/users/updateUsersDepartments", data)
                        .then((res) => {
                            const data = res.data;
                            console.log(data);
                        })
                        .catch((err) => console.log(err));
                }

                setSettings((prevState) => {
                    return { ...prevState, ...settings };
                });
                ValidateValues(values);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function ValidateValues(values) {
        const valids = { ...formData.valids };

        for (const [key, value] of Object.entries(values)) {
            if (key == "user" || key == "department") {
                if (value.name != "") {
                    valids[key] = true;
                }
            }
            if (key == "sessionLength") {
                if (value != "") {
                    valids[key] = true;
                }
            }
        }

        setFormData((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    function handleDropDownItemSelected(dropdown, item) {
        const values = { ...formData.values };
        const valids = { ...formData.valids };

        let isValid = false;
        if (dropdown == "sessionLength") {
            if (item != "") {
                isValid = true;
            }
        } else {
            if (item.name != "") {
                isValid = true;
            }
        }

        values[dropdown] = item;
        valids[dropdown] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        let isValid = false;
        if (value != "") {
            isValid = true;
        }
        values[name] = value;
        valids[name] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    //REPEAT FUNCTIONS
    function handleRepeatClicked(event) {
        const { name, checked } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values[name] = checked;
        if (!checked) {
            values["repeatType"] = "";
        }
        valids[name] = !checked;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleRepeatTypeClicked(event) {
        const { name, checked } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values["repeatType"] = name;

        let isValid = false;
        if (values.repeat && values.repeatType != "") {
            isValid = true;
        }

        valids["repeat"] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        if (name == "endOfYear" && checked) {
            const endDate = endOfYearDate();
            values.repeatUntil = new Date(endDate);
        }

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleDateChange(date) {
        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values["repeatUntil"] = date;

        let isValid = true;
        let errorMsg = "";

        const currentDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY");
        const untilDate = moment(values.repeatUntil, "DD/MM/YYYY");
        const twoYearDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY").add(2, "y");

        if (untilDate.isSameOrBefore(currentDate)) {
            errorMsg = "Please select a date after the selected booking date";
            isValid = false;
        }
        if (untilDate.isAfter(twoYearDate)) {
            errorMsg = "You cannot repeat a booking for more than two years";
            isValid = false;
        }

        valids.repeatUntil = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue, dateErrorMessage: errorMsg };
        });
    }

    function endOfYearDate() {
        let endDate = moment();
        const today = moment();

        if (today.month() > 6) {
            const date = moment("31/07/" + today.year(), "DD/MM/YYYY");
            date.add(1, "y");
            endDate = moment(date, "DD/MM/YYYY");
        } else {
            const date = moment("31/07/" + today.year(), "DD/MM/YYYY");
            endDate = moment(date, "DD/MM/YYYY");
        }

        return endDate;
    }

    function handleBook() {
        if (!formData.wholeFormValid) {
            setFormData((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            let startDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY");
            let bookingType = "";
            let repeatType = "";
            let repeatUntil = "";
            let sessions = [];

            const IDs = globalVars.roomDetail.sessionID.split("-");
            if (globalVars.roomDetail.layoutData.layout == "Timetable") {
                if (!IDs[1].toString().includes("b")) {
                    let firstIndex = parseInt(IDs[1]);

                    for (var i = 0; i < formData.values.sessionLength; i++) {
                        sessions.push(firstIndex);
                        firstIndex++;
                    }
                } else {
                    sessions.push(IDs[1]);
                }
            } else if (globalVars.roomDetail.layoutData.layout == "Diary") {
                let sTime = moment(globalVars.roomDetail.sessionLabel, "HH:mm");
                const fTime = moment(formData.values.sessionLength, "HH:mm");

                while (sTime.isBefore(fTime)) {
                    sessions.push(sTime.format("HH:mm").replace(":", ""));
                    sTime.add(globalVars.roomDetail.timeInterval, "m");
                }
            }

            if (formData.values.repeat) {
                bookingType = "repeat";

                if (formData.values.repeatType == "daily") {
                    repeatType = "daily";
                    repeatUntil = formData.values.repeatUntil.toLocaleDateString();
                } else if (formData.values.repeatType == "weekly" || formData.values.repeatType == "endOfYear") {
                    repeatType = "weekly";
                    repeatUntil = formData.values.repeatUntil.toLocaleDateString();
                }
            } else {
                bookingType = "single";
                repeatUntil = "";
            }

            //WEEK SYSTEM
            let weekSysWeeks = "";
            for (const holiday of organisation.holidays) {
                if (holiday.titleUUID == globalVars.roomDetail.weekUUID) {
                    weekSysWeeks = holiday.weeks;
                    break;
                }
            }

            const data = {
                orgID: params.orgID,
                roomID: globalVars.roomDetail.roomID,
                user: formData.values.user.uuid,
                departmentID: formData.values.department.uuid,
                sessionDes: formData.values.sessionDes,
                sessionTotal: sessions.length,
                sessions: sessions.toString(),
                comments: formData.values.comments,
                bookingType: bookingType,
                repeatType: repeatType,
                startDate: startDate.format("DD/MM/YYYY"),
                repeatUntil: repeatUntil,
                createdBy: user.uuid,
                dayList: globalVars.roomDetail.dayList,
                weekSystem: globalVars.roomDetail.weekSystem,
                weekSysWeeks: weekSysWeeks
            };

            Axios.post("/bookings/createBooking", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        if (data.userError == "null") {
                            navigate("/org/" + params.orgID);
                        } else {
                            setModal(() => {
                                return { heading: "Booking", message: data.message, open: true };
                            });
                        }
                    } else {
                        if (data.message == "MSQL error") {
                            setErrorMsg(() => {
                                return { open: true };
                            });
                        } else {
                            setModal(() => {
                                return { heading: "Booking", message: data.message, open: true };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <Row>
                        <Col></Col>
                        <Col xs={8}>
                            <Row>
                                <Col style={BookingsStyles.topInfo}>
                                    <strong>Date: {globalVars.roomDetail.date}</strong>
                                </Col>
                                <Col style={BookingsStyles.topInfo}>
                                    <strong>Room: {globalVars.roomDetail.roomName}</strong>
                                </Col>
                                <Col style={BookingsStyles.topInfo}>
                                    <strong>Session: {globalVars.roomDetail.sessionLabel}</strong>
                                </Col>
                            </Row>
                            <hr />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col xs={8}>
                            <Row>
                                <Col style={BookingsStyles.content}>
                                    User:
                                    {settings.showUserDropdown ? (
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant={formData.errorOnForm && !formData.valids.user ? "danger" : "primary"}>
                                                    {formData.values.user.name}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={OrganisationHomeStyles.dropdownItems}>
                                                    {settings.users.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} onClick={handleDropDownItemSelected.bind(this, "user", item)}>
                                                                {item.name}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <p
                                                style={
                                                    formData.errorOnForm && !formData.valids.user
                                                        ? BookingsStyles.errorText_Show
                                                        : BookingsStyles.errorText_Hidden
                                                }
                                            >
                                                Please select an user
                                            </p>
                                        </div>
                                    ) : (
                                        <div>{formData.values.user.name}</div>
                                    )}
                                    <br />
                                    Department:
                                    {settings.showDepartmentDropdown ? (
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant={formData.errorOnForm && !formData.valids.department ? "danger" : "primary"}>
                                                    {formData.values.department.name}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={OrganisationHomeStyles.dropdownItems}>
                                                    {settings.departments.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={handleDropDownItemSelected.bind(this, "department", item)}
                                                            >
                                                                {item.name}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <p
                                                style={
                                                    formData.errorOnForm && !formData.valids.department
                                                        ? BookingsStyles.errorText_Show
                                                        : BookingsStyles.errorText_Hidden
                                                }
                                            >
                                                Please select a department
                                            </p>
                                        </div>
                                    ) : (
                                        <div>{formData.values.department.name}</div>
                                    )}
                                    <br />
                                    {settings.sessionDescriptionTitle}:
                                    <Form.Group>
                                        <Form.Control
                                            isInvalid={formData.errorOnForm && !formData.valids.sessionDes ? true : false}
                                            type="text"
                                            name="sessionDes"
                                            value={formData.values.sessionDes}
                                            onChange={handleTextChange}
                                        />
                                        <p
                                            style={
                                                formData.errorOnForm && !formData.valids.sessionDes
                                                    ? BookingsStyles.errorText_Show
                                                    : BookingsStyles.errorText_Hidden
                                            }
                                        >
                                            Please put a {settings.sessionDescriptionTitle}
                                        </p>
                                    </Form.Group>
                                    <br />
                                    Comments:
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="comments"
                                        value={formData.values.comments}
                                        onChange={handleTextChange}
                                    />
                                </Col>
                                <Col style={BookingsStyles.content}>
                                    <Row>
                                        <Col>
                                            Session Length:
                                            {settings.showSessionDropdown ? (
                                                <div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant={formData.errorOnForm && !formData.valids.sessionLength ? "danger" : "primary"}
                                                        >
                                                            {formData.values.sessionLength}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={OrganisationHomeStyles.dropdownItems}>
                                                            {settings.sessions.map((item, index) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        key={index}
                                                                        onClick={handleDropDownItemSelected.bind(this, "sessionLength", item)}
                                                                    >
                                                                        {item}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p
                                                        style={
                                                            formData.errorOnForm && !formData.valids.sessionLength
                                                                ? BookingsStyles.errorText_Show
                                                                : BookingsStyles.errorText_Hidden
                                                        }
                                                    >
                                                        Please select an user
                                                    </p>
                                                </div>
                                            ) : (
                                                <> {formData.values.sessionLength}</>
                                            )}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            {userProfile.room_Repeat ? (
                                                <div>
                                                    <Form.Group>
                                                        <Form.Check
                                                            style={BookingsStyles.checkSideBySide_b}
                                                            type="checkbox"
                                                            label="Repeat"
                                                            name="repeat"
                                                            checked={formData.values.repeat}
                                                            onChange={handleRepeatClicked}
                                                        />
                                                    </Form.Group>
                                                    <br />
                                                    {formData.values.repeat && (
                                                        <div>
                                                            <Form.Group>
                                                                <Form.Check
                                                                    style={BookingsStyles.checkSideBySide_b}
                                                                    type="checkbox"
                                                                    label="Daily"
                                                                    name="daily"
                                                                    checked={formData.values.repeatType == "daily" ? true : false}
                                                                    onChange={handleRepeatTypeClicked}
                                                                />
                                                                <Form.Check
                                                                    style={BookingsStyles.checkSideBySide}
                                                                    type="checkbox"
                                                                    label="Weekly"
                                                                    name="weekly"
                                                                    checked={formData.values.repeatType == "weekly" ? true : false}
                                                                    onChange={handleRepeatTypeClicked}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Check
                                                                    style={BookingsStyles.checkSideBySide}
                                                                    type="checkbox"
                                                                    label="End of Year (Weekly)"
                                                                    name="endOfYear"
                                                                    checked={formData.values.repeatType == "endOfYear" ? true : false}
                                                                    onChange={handleRepeatTypeClicked}
                                                                />
                                                            </Form.Group>
                                                            <br />
                                                            {formData.errorOnForm && !formData.valids.repeat && (
                                                                <p style={BookingsStyles.errorText_Show}>Please select a repeat type</p>
                                                            )}

                                                            <Row>
                                                                <Col></Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        Until:
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            selected={new Date(formData.values.repeatUntil)}
                                                                            onChange={handleDateChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <p
                                                                style={
                                                                    formData.errorOnForm && !formData.valids.repeatUntil
                                                                        ? BookingsStyles.errorText_Show
                                                                        : BookingsStyles.errorText_Hidden
                                                                }
                                                            >
                                                                {formData.dateErrorMessage}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col style={BookingsStyles.bookBtn}>
                                            <Button onClick={handleBook}>Book</Button>
                                            <p
                                                style={
                                                    formData.errorOnForm && !formData.wholeFormValid
                                                        ? BookingsStyles.errorText_Show
                                                        : BookingsStyles.errorText_Hidden
                                                }
                                            >
                                                There are errors on this form
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                </CenterContainer>
            </Container>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorMsg.open} onHide={handleErrorMsgClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking Error!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    I am very sorry but you have run into an error.
                    <br />
                    <br />
                    Please inform the Senior Admin of your organisation&apos;s account on My-STAFF.co.uk <br /> <br />
                    <strong>Week System</strong>
                    <br />
                    <br />
                    If you have the week system enabled, please make sure that all weeks are assigned to a title.
                    <br /> <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleErrorMsgClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Book;
