export const alignRight = {
    textAlign: "right",
    color: "red"
};

export const alignLeft = {
    textAlign: "left"
};

export const valignCenter = {
    display: "table",
    width: "100%",
    height: "100%"
};

export const valign = {
    display: "table-cell",
    verticalAlign: "middle"
};

export const helpImage = {
    width: "40px",
    height: "50px",
    cursor: "pointer"
};

export const warning = {
    color: "red"
};

export const buttonWidth = {
    width: "100%",
    height: "50px",
    fontSize: "1rem",
    textAlign: "center",
    padding: "0"
};

export const hide = {
    visibility: "hidden"
};

export const userProfileTabs = {
    backgroundColor: "white",
    height: "300px",
    textAlign: "left",
    paddingTop: "10px",
    paddingLeft: "10px"
};

export const default_ListItem = {
    color: "red"
};

export const prioritiesRight = {
    float: "right",
    verticalAlign: "middle"
};

export const prioritiesLeft = {
    float: "left"
};
