import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Card, Spinner } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as ServicesStyles from "../../styles/services";

//COMPONENTS
import CenterContainer from "../../../../../globalStyles/centerContainer";

function Services() {
    const [deleteDateBookings, setDeleteDateBookings] = useState({
        open: false,
        organisations: []
    });

    const [isChanging, setIsChanging] = useState(false);

    function handleDeleteDateClose() {
        setDeleteDateBookings((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleDeleteDateBookings() {
        Axios.post("/siteAdministration/services/getOrganisations")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDeleteDateBookings((prevState) => {
                        return { ...prevState, organisations: data.organisations, open: true };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDeleteDateProcess(orgID) {
        setIsChanging(true);
        const data = { orgID: orgID };
        Axios.post("/siteAdministration/services/changeDeleteDate", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setIsChanging(false);
                }
            })
            .catch((err) => console.log(err));
    }
    return (
        <div>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <Row>
                        <Col>
                            <div style={ServicesStyles.oneOffServicesContainer}>
                                One-Off Services <br /> <br />
                                <Button variant="warning" onClick={handleDeleteDateBookings}>
                                    Run Delete Date Bookings
                                </Button>
                                <br />
                                <br />
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                </CenterContainer>
            </Container>
            <Modal show={deleteDateBookings.open} onHide={handleDeleteDateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Date Bookings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isChanging ? (
                        <div style={ServicesStyles.spinner}>
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <div>
                            {deleteDateBookings.organisations.map((org, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={ServicesStyles.cardStyle} onClick={handleDeleteDateProcess.bind(this, org.orgID)}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>{org.name}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDeleteDateClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Services;
