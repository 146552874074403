import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import ServerPath from "../../../../../../ServerPath";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function WrongLogin(props) {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "STAFF - Not Connected";
        ServerPath();
    }, []);

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h3>This is not the login method you use to log in</h3>
                    <Button variant="warning" onClick={() => navigate("/org/" + params.orgID + "/")}>
                        Back
                    </Button>
                    <br />
                    <br />
                </CenterContainer>
            </Container>
        </div>
    );
}

export default WrongLogin;
