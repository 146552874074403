import * as ReducerActions from "../actions/globalVars";
import update from "react-addons-update";

const initalState = {
	roomDetail: {
		roomName: "",
		roomID: "",
		sessionID: "",
		sessionLabel: "",
		date: "",
		weekBeginDate: "",
		totalSessions: 0,
		dayList: [],
		layoutData: {},
		weekSystem: false,
		weekUUID: "",
		startTime: "",
		finishTime: "",
		timeInterval: "",
	},

	collideBookingsUpdate: false,

	fromSignIn: false,
};

export default (state = initalState, action) => {
	switch (action.type) {
		case ReducerActions.UPDATE_ROOM_NAME:
			return update(state, {
				roomDetail: {
					roomName: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_ID:
			return update(state, {
				roomDetail: {
					roomID: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_LAYOUT_DATA:
			return update(state, {
				roomDetail: {
					layoutData: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_SESSION_ID:
			return update(state, {
				roomDetail: {
					sessionID: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_SESSION_LABEL:
			return update(state, {
				roomDetail: {
					sessionLabel: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_DATE:
			return update(state, {
				roomDetail: {
					date: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_WEEK_BEGIN:
			return update(state, {
				roomDetail: {
					weekBeginDate: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_TOTAL_SESSIONS:
			return update(state, {
				roomDetail: {
					totalSessions: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_DAY_LIST:
			return update(state, {
				roomDetail: {
					dayList: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_WEEK_SYSTEM:
			return update(state, {
				roomDetail: {
					weekSystem: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_ROOM_WEEK_UUID:
			return update(state, {
				roomDetail: {
					weekUUID: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_DIARY_START_TIME:
			return update(state, {
				roomDetail: {
					startTime: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_DIARY_FINISH_TIME:
			return update(state, {
				roomDetail: {
					finishTime: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_DIARY_TIME_INTERVAL:
			return update(state, {
				roomDetail: {
					timeInterval: { $set: action.value },
				},
			});
		case ReducerActions.UPDATE_FORCE_COLLIDED_BOOKINGS:
			return { ...state, collideBookingsUpdate: action.value };
		case ReducerActions.UPDATE_FROM_SIGNIN:
			return { ...state, fromSignIn: action.value };
		case ReducerActions.RESET_TO_DEFAULTS:
			return { ...state, roomDetail: ReducerActions.ResetDefaults };
		default:
			return state;
	}
};
