import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Row, Col, Card, Button } from "react-bootstrap";

//ACTIONS
import * as MainAdminSettingsActions from "../../../../../globalSettings/mainAdminSettings";

//COMPONENTS
import OrganisationItem from "./organisationItem";

//STYLES
import * as AdminStyles from "../../styles/admin";

function OrganisationList() {
    const MainAdminGlobalSettings = useSelector((state) => state.MainAdminGlobalSettings);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        Axios.post("/siteAdministration/getAllOrganisations")
            .then((res) => {
                MainAdminSettingsActions.UpdateOrganisationsSettings(dispatch, res.data);
                setLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <div style={AdminStyles.addOrganisationBtn}>
                <Button variant="warning" onClick={() => navigate("/administrator/services")}>
                    Services
                </Button>
                <Button variant="warning" onClick={() => navigate("/administrator/organisationRegister")}>
                    Add Organisation
                </Button>
            </div>
            <br />
            {loaded ? (
                <div>
                    <Card body style={AdminStyles.organisationsListTitles}>
                        <Row>
                            <Col>
                                <label>Name</label>
                            </Col>
                            <Col>
                                <label>Email</label>
                            </Col>
                            <Col>
                                <label>Point of Contact</label>
                            </Col>
                            <Col>
                                <label>Org ID</label>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    {MainAdminGlobalSettings.organisations.map((organisation, index) => {
                        return (
                            <div key={index}>
                                <OrganisationItem
                                    name={organisation.name}
                                    email={organisation.POC_Email}
                                    poc={organisation.POC_Name}
                                    orgID={organisation.orgID}
                                    allocatedRooms={organisation.allocatedRooms}
                                    redeemedRooms={organisation.redeemedRooms}
                                />
                                <br />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default OrganisationList;
