//NEW USER PROFILE SETTING - when add the options to edit new settings, 'id' is the name of the setting and 'name' is the name of the section.

import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Container, ListGroup, Row, Modal, Image, Form, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//ACTIONS
import * as UserProfileAdminSettingsActions from "../../../../../../store/actions/globalSettings/userProfileAdminGlobalSettings";

//IMAGES
import helpImg from "../../../images/help.png";

//STYLES
import * as OrganisationAdminStyles from "../../../styles/organisationAdmin";
import * as UserProfilesStyles from "../styles/userProfiles";

function ProfileSettings() {
    const params = useParams();

    const UserProfileAdminGlobalSettings = useSelector((state) => state.UserProfileAdminGlobalSettings);
    const AdminProfileGlobalSettings = useSelector((state) => state.AdminProfileGlobalSettings);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (AdminProfileGlobalSettings.uuid == "") {
            console.log(AdminProfileGlobalSettings.uuid);
            navigate("/org/" + params.orgID + "/organisationAdmin");
        } else {
            if (AdminProfileGlobalSettings.uuid != "default") {
                loadProfile(false);
            } else {
                setSettings((prevState) => {
                    return { ...prevState, defaultPro: true };
                });
                loadProfile(true);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function handleResize() {
        let viewBtnSize = document.getElementById("viewBtn").offsetWidth; //50
        let writeBtnSize = document.getElementById("writeBtn").offsetWidth; //60
        let editBtnSize = document.getElementById("editBtn").offsetWidth; //50
        let deleteBtnSize = document.getElementById("deleteBtn").offsetWidth; //70
        let repeatBtnSize = document.getElementById("repeatBtn").offsetWidth; //70

        setSettings((prevState) => {
            return {
                ...prevState,
                viewBtnSize: viewBtnSize,
                writeBtnSize: writeBtnSize,
                editBtnSize: editBtnSize,
                deleteBtnSize: deleteBtnSize,
                repeatBtnSize: repeatBtnSize
            };
        });
    }

    const [settings, setSettings] = useState({
        users: [],
        userUUID: "",
        nonProfileUsers: [],
        profileUsers: [],
        rooms: [],
        defaultPro: false,

        viewBtnSize: 0,
        writeBtnSize: 0,
        editBtnSize: 0,
        deleteBtnSize: 0,
        repeatBtnSize: 0,

        weeksTitle: "Weeks"
    });

    const [userHistory, setHistory] = useState({
        users: []
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [help, setHelp] = useState({
        open: false,
        heading: "",
        message: "",
        message2: ""
    });

    function handleCloseHelp() {
        setHelp((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [tabs, setTabs] = useState({
        bookings: true,
        admin: false,
        weeks: false
    });

    function loadProfile(defaultPro) {
        const data = { orgID: params.orgID, profileUUID: AdminProfileGlobalSettings.uuid, userSettings: UserProfileAdminGlobalSettings };
        Axios.post("/orgAdmin/userProfiles/loadProfile", data)
            .then((res) => {
                const data = res.data;

                //USERS
                getProfileUsers(defaultPro, data.users);

                //ROOMS
                const UPRooms = data.UPRooms;
                const orgRooms = data.orgRooms;

                let rooms = [];

                if (!defaultPro) {
                    orgRooms.map((room) => {
                        let exsit = false;

                        UPRooms.map((UPRoom) => {
                            if (room.uuid == UPRoom.roomUUID) {
                                exsit = true;
                                const obj = {
                                    uuid: room.uuid,
                                    name: room.name,
                                    view: UPRoom.view == "true",
                                    write: UPRoom.write == "true",
                                    edit: UPRoom.edit == "true",
                                    delete: UPRoom.delete == "true",
                                    repeat: UPRoom.repeat == "true"
                                };
                                rooms.push(obj);
                            }
                        });

                        if (!exsit) {
                            const roomObj = {
                                uuid: room.uuid,
                                name: room.name,
                                view: false,
                                write: false,
                                edit: false,
                                delete: false,
                                repeat: false
                            };
                            rooms.push(roomObj);
                        }
                    });

                    //USER SETTINGS
                    let settings = {};
                    const sectionKeys = Object.keys(UserProfileAdminGlobalSettings);

                    for (const key of sectionKeys) {
                        const settingKeys = Object.keys(UserProfileAdminGlobalSettings[key]);
                        const sectionSettings = {};
                        for (const setting of settingKeys) {
                            const type = UserProfileAdminGlobalSettings[key][setting].type;
                            let value = null;
                            if (type === "Boolean") {
                                value = data.userSettings[setting] === "true";
                            } else if (type === "Text") {
                                value = data.userSettings[setting];
                            }
                            sectionSettings[setting] = { type: type, value: value };
                        }
                        settings[key] = sectionSettings;
                    }
                    //console.log(settings);
                    dispatch(UserProfileAdminSettingsActions.SetSettings(settings));

                    let title = "";
                    const id = settings["weeks"]["goBackWeeks"].value;

                    if (id === "0") {
                        title = "None";
                    } else if (id === "1") {
                        title = `${id} week`;
                    } else {
                        title = `${id} weeks`;
                    }

                    //SAVING THE ROOMS AND THE WEEK TITLE
                    setSettings((prevState) => {
                        return { ...prevState, rooms: rooms, weeksTitle: title };
                    });
                } else {
                    orgRooms.map((room) => {
                        const roomObj = { uuid: room.uuid, name: room.name, view: true, write: true, edit: false, delete: false, repeat: false };
                        rooms.push(roomObj);
                    });

                    setSettings((prevState) => {
                        return { ...prevState, rooms: rooms };
                    });
                }

                handleResize();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getProfileUsers(defaultPro, allUsers) {
        let nonProfileusers = [];
        let profileUsers = [];

        if (!defaultPro) {
            allUsers.map((user) => {
                const profiles = user.userProfiles.split(",");
                if (profiles.includes(AdminProfileGlobalSettings.uuid.toString())) {
                    profileUsers.push(user);
                } else {
                    nonProfileusers.push(user);
                }
            });

            setSettings((prevState) => {
                return { ...prevState, nonProfileUsers: nonProfileusers, profileUsers: profileUsers, users: allUsers, userUUID: "" };
            });
        } else {
            allUsers.map((user) => {
                if (user.userProfiles == "") {
                    profileUsers.push(user);
                }
            });

            setSettings((prevState) => {
                return { ...prevState, profileUsers: profileUsers, users: allUsers, userUUID: "" };
            });
        }
    }

    function handleRoomSelectProperty(uuid, property) {
        const rms = settings.rooms;

        rms.forEach((room) => {
            if (room.uuid == uuid) {
                if (room[property]) {
                    //turns to false
                    room[property] = false;

                    if (property == "view") {
                        room.write = false;
                        room.edit = false;
                        room.delete = false;
                        room.repeat = false;
                    }
                } else {
                    //turns to true
                    room[property] = true;
                }
            }
        });

        setSettings((prevState) => {
            return { ...prevState, rooms: rms };
        });
    }

    function handleUpdateProfile() {
        const usersToUpdate = [];

        userHistory.users.map((id) => {
            settings.users.map((user) => {
                if (user.uuid == id) {
                    usersToUpdate.push(user);
                }
            });
        });

        const data = {
            orgID: params.orgID,
            profileUUID: AdminProfileGlobalSettings.uuid,
            usersToUpdate: usersToUpdate,
            profileUserCount: settings.profileUsers.length,
            rooms: settings.rooms,
            userSettings: UserProfileAdminGlobalSettings
        };

        Axios.post("/orgAdmin/userProfiles/saveProfile", data)
            .then((res) => {
                const data = res.data;
                if (data.message == "User Profile Updated") {
                    setModal({ heading: "Profile Settings", message: data.message, open: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //USERS
    function handleUserClicked(uuid) {
        setSettings((prevState) => {
            return { ...prevState, userUUID: uuid };
        });
    }

    function handleMoveUserIntoProfile() {
        if (settings.userUUID == "") {
            setModal({ heading: "Move User", message: "You need to select an user first", open: true });
        } else {
            settings.users.map((user) => {
                if (user.uuid == settings.userUUID) {
                    let profiles = [];

                    if (user.userProfiles != "") {
                        profiles = user.userProfiles.split(",");
                    }

                    if (profiles.length > 5) {
                        setModal({ heading: "Move User", message: "This user has already got 5 profile assigned to them", open: true });
                    } else {
                        if (profiles.length == 0) {
                            profiles.push(AdminProfileGlobalSettings.uuid);
                        } else {
                            profiles.push(AdminProfileGlobalSettings.uuid);
                        }

                        user.userProfiles = profiles.toString();
                    }

                    insertUserHistory(settings.userUUID);
                    getProfileUsers(settings.defaultPro, settings.users);
                }
            });
        }
    }

    function handleMoveUserOutProfile() {
        if (settings.userUUID == "") {
            setModal({ heading: "Move User", message: "You need to select an user first", open: true });
        } else {
            settings.users.map((user) => {
                if (user.uuid == settings.userUUID) {
                    let profiles = [];

                    if (user.userProfiles != "") {
                        profiles = user.userProfiles.split(",");
                    }

                    const ID = profiles.indexOf(AdminProfileGlobalSettings.uuid.toString());

                    profiles.splice(ID, 1);

                    user.userProfiles = profiles.toString();

                    insertUserHistory(settings.userUUID);
                    getProfileUsers(settings.defaultPro, settings.users);
                }
            });
        }
    }

    function insertUserHistory(uuid) {
        if (!userHistory.users.includes(uuid)) {
            setHistory(() => {
                return { users: [...userHistory.users, uuid] };
            });
        }
    }

    function showHelp() {
        const message =
            "The priority system only affects the rooms in the profiles. If the same room in two different profiles is marked ‘view’, the priority profile settings are set for that room.";
        const message2 =
            "The priority system does not affect any other settings in the profile. If a setting is turned on in any profile that is assigned to a particular user, the setting is on for that user.";

        setHelp({ heading: "Help: Priority System", message: message, message2: message2, open: true });
    }

    function changeTabs(section) {
        setTabs({ [section]: true });
    }

    function onChange() {
        //DO NOTHING
    }

    function checkSettingOnClick(event) {
        const { id, name, checked } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateSetting(name, id, checked));
    }

    function changeAdminSetting(event) {
        const { id, checked } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateAdministratorSettings(id, checked));
    }

    function changeWeekSettingHandle(event) {
        const { id, name } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateSetting(name, "goBackWeeks", id));

        let title = "";

        if (id === "0") {
            title = "None";
        } else if (id === "1") {
            title = `${id} week`;
        } else {
            title = `${id} weeks`;
        }

        setSettings((prevState) => {
            return { ...prevState, weeksTitle: title };
        });
    }

    return (
        <div>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>Profile Settings</h1>
                    <h2>{AdminProfileGlobalSettings.name}</h2>
                    <br />
                    <div style={UserProfilesStyles.alignRight}>
                        {!settings.defaultPro ? (
                            <div>
                                <Button variant="primary" onClick={handleUpdateProfile}>
                                    Update
                                </Button>
                                <Image style={UserProfilesStyles.helpImage} src={helpImg} onClick={showHelp}></Image>
                            </div>
                        ) : null}
                    </div>
                    <Row style={OrganisationAdminStyles.normalText}>
                        <strong>Users:</strong>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <strong>Users in this profile:</strong>
                            <div style={{ ...UserProfilesStyles.alignLeft, ...OrganisationAdminStyles.scrollable_300 }}>
                                <ListGroup>
                                    {settings.defaultPro ? (
                                        <div>
                                            {settings.profileUsers.map((user, index) => {
                                                return (
                                                    <ListGroup.Item href={"#" + index} key={index}>
                                                        {user.displayName}
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div>
                                            {settings.profileUsers.map((user, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        href={"#" + index}
                                                        key={index}
                                                        action
                                                        onClick={() => handleUserClicked(user.uuid)}
                                                    >
                                                        {user.displayName}
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </div>
                                    )}
                                </ListGroup>
                            </div>
                        </Col>
                        <Col>
                            <div style={UserProfilesStyles.valignCenter}>
                                <span style={UserProfilesStyles.valign}>
                                    {!settings.defaultPro ? (
                                        <div>
                                            <Button onClick={handleMoveUserIntoProfile}>Move into profile</Button>
                                            <br />
                                            <Button onClick={handleMoveUserOutProfile}>Move out of profile</Button>
                                        </div>
                                    ) : null}
                                </span>
                            </div>
                        </Col>
                        <Col>
                            {!settings.defaultPro ? (
                                <div>
                                    <strong>Users not in this profile:</strong>
                                    <div style={{ ...UserProfilesStyles.alignLeft, ...OrganisationAdminStyles.scrollable_300 }}>
                                        <ListGroup>
                                            {settings.nonProfileUsers.map((user, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        href={"#" + index}
                                                        key={index}
                                                        action
                                                        onClick={() => handleUserClicked(user.uuid)}
                                                    >
                                                        {user.displayName}
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </ListGroup>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <strong>
                                        This profile cannot be updated as it is the default profile which the system uses if a user is not assigned to
                                        a profile. <br />
                                        <br />
                                        This is the profile for User Level which is set up by High-View Studios. <br /> <br />
                                        An &apos;user&apos; can see all the rooms and make bookings.
                                        <br /> <br />
                                        An &apos;admin&apos; can make bookings on behalf of any user and any department. They are also able to make
                                        repeated bookings and able to delete any booking. <br /> <br />
                                        To have custom settings please make a profile and assign users to it.
                                    </strong>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={UserProfilesStyles.alignLeft}>
                            <strong>Rooms:</strong>
                        </Col>
                        <Col style={UserProfilesStyles.alignLeft}>{!settings.defaultPro ? <strong>User Settings:</strong> : null}</Col>
                    </Row>
                    <Row>
                        <Col style={{ ...UserProfilesStyles.alignLeft, ...UserProfilesStyles.warning }}>
                            <strong>Rooms are affected by the priority system, see &apos;help&apos; for more information.</strong> <br />
                            <strong>The edit feature is not built yet.</strong>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col style={UserProfilesStyles.alignRight}>
                            <Button variant="primary" disabled>
                                Selected
                            </Button>
                            <Button variant="outline-primary" disabled>
                                Unselected
                            </Button>
                        </Col>
                        <Col style={UserProfilesStyles.alignLeft}>
                            {!settings.defaultPro ? (
                                <div>
                                    <Button
                                        variant={tabs.bookings ? "primary" : "outline-primary"}
                                        onClick={() => {
                                            changeTabs("bookings");
                                        }}
                                    >
                                        Bookings
                                    </Button>
                                    <Button
                                        variant={tabs.admin ? "primary" : "outline-primary"}
                                        onClick={() => {
                                            changeTabs("admin");
                                        }}
                                    >
                                        Administrator
                                    </Button>
                                    <Button
                                        variant={tabs.weeks ? "primary" : "outline-primary"}
                                        onClick={() => {
                                            changeTabs("weeks");
                                        }}
                                    >
                                        Weeks
                                    </Button>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={UserProfilesStyles.alignLeft}>
                            <div style={OrganisationAdminStyles.scrollable_300}>
                                <ListGroup>
                                    {settings.rooms.map((room, index) => {
                                        return (
                                            <ListGroup.Item key={index}>
                                                {settings.defaultPro ? (
                                                    <div>
                                                        <Row>
                                                            <Col>{room.name}</Col>
                                                            <Col>
                                                                <Button variant={room.view ? "primary" : "outline-primary"} disabled>
                                                                    View
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                {room.view ? (
                                                                    <Button variant={room.write ? "primary" : "outline-primary"} disabled>
                                                                        Write
                                                                    </Button>
                                                                ) : null}
                                                            </Col>
                                                            <Col>
                                                                {room.view ? (
                                                                    <Button variant={room.edit ? "primary" : "outline-primary"} disabled>
                                                                        Edit
                                                                    </Button>
                                                                ) : null}
                                                            </Col>
                                                            <Col>
                                                                {room.view ? (
                                                                    <Button variant={room.delete ? "primary" : "outline-primary"} disabled>
                                                                        Delete
                                                                    </Button>
                                                                ) : null}
                                                            </Col>
                                                            <Col>
                                                                {room.view ? (
                                                                    <Button variant={room.repeat ? "primary" : "outline-primary"} disabled>
                                                                        Repeat
                                                                    </Button>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Row>
                                                            <Col style={UserProfilesStyles.buttonWidth} className="col-lg-2">
                                                                {room.name}
                                                            </Col>
                                                            <Col className="col-lg-10">
                                                                <Row>
                                                                    <Col className="col-lg-2"></Col>
                                                                    <Col className="col-lg-2">
                                                                        <Button
                                                                            id={index == 0 ? "viewBtn" : null}
                                                                            style={UserProfilesStyles.buttonWidth}
                                                                            variant={room.view ? "primary" : "outline-primary"}
                                                                            onClick={() => handleRoomSelectProperty(room.uuid, "view")}
                                                                        >
                                                                            {settings.viewBtnSize <= 50 ? <i className="fas fa-eye"></i> : "View"}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="col-lg-2">
                                                                        <Button
                                                                            id={index == 0 ? "writeBtn" : null}
                                                                            style={
                                                                                room.view
                                                                                    ? UserProfilesStyles.buttonWidth
                                                                                    : {
                                                                                          ...UserProfilesStyles.buttonWidth,
                                                                                          ...UserProfilesStyles.hide
                                                                                      }
                                                                            }
                                                                            variant={room.write ? "primary" : "outline-primary"}
                                                                            onClick={() => handleRoomSelectProperty(room.uuid, "write")}
                                                                        >
                                                                            {settings.writeBtnSize <= 60 ? (
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            ) : (
                                                                                "Write"
                                                                            )}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="col-lg-2">
                                                                        <Button
                                                                            id={index == 0 ? "editBtn" : null}
                                                                            disabled
                                                                            style={
                                                                                room.view
                                                                                    ? UserProfilesStyles.buttonWidth
                                                                                    : {
                                                                                          ...UserProfilesStyles.buttonWidth,
                                                                                          ...UserProfilesStyles.hide
                                                                                      }
                                                                            }
                                                                            variant={room.edit ? "primary" : "outline-light"}
                                                                            onClick={() => handleRoomSelectProperty(room.uuid, "edit")}
                                                                        >
                                                                            {settings.editBtnSize <= 50 ? <i className="fas fa-edit"></i> : "Edit"}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="col-lg-2">
                                                                        <Button
                                                                            id={index == 0 ? "deleteBtn" : null}
                                                                            style={
                                                                                room.view
                                                                                    ? UserProfilesStyles.buttonWidth
                                                                                    : {
                                                                                          ...UserProfilesStyles.buttonWidth,
                                                                                          ...UserProfilesStyles.hide
                                                                                      }
                                                                            }
                                                                            variant={room.delete ? "primary" : "outline-primary"}
                                                                            onClick={() => handleRoomSelectProperty(room.uuid, "delete")}
                                                                        >
                                                                            {settings.deleteBtnSize <= 70 ? (
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            ) : (
                                                                                "Delete"
                                                                            )}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="col-lg-2">
                                                                        <Button
                                                                            id={index == 0 ? "repeatBtn" : null}
                                                                            style={
                                                                                room.view
                                                                                    ? UserProfilesStyles.buttonWidth
                                                                                    : {
                                                                                          ...UserProfilesStyles.buttonWidth,
                                                                                          ...UserProfilesStyles.hide
                                                                                      }
                                                                            }
                                                                            variant={room.repeat ? "primary" : "outline-primary"}
                                                                            onClick={() => handleRoomSelectProperty(room.uuid, "repeat")}
                                                                        >
                                                                            {settings.repeatBtnSize <= 70 ? (
                                                                                <i className="fas fa-redo-alt"></i>
                                                                            ) : (
                                                                                "Repeat"
                                                                            )}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                        </Col>
                        <Col style={UserProfilesStyles.alignLeft}>
                            {!settings.defaultPro ? (
                                <div>
                                    <div style={UserProfilesStyles.userProfileTabs}>
                                        {tabs.bookings ? (
                                            <div>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="viewAllUsers"
                                                    name="bookings"
                                                    checked={UserProfileAdminGlobalSettings.bookings.viewAllUsers.value}
                                                    label="View all users"
                                                    onClick={checkSettingOnClick}
                                                    onChange={onChange}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    id="viewAllDepartments"
                                                    name="bookings"
                                                    checked={UserProfileAdminGlobalSettings.bookings.viewAllDepartments.value}
                                                    label="View all departments"
                                                    onClick={checkSettingOnClick}
                                                    onChange={onChange}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    id="viewAllOrgBookings"
                                                    name="bookings"
                                                    checked={UserProfileAdminGlobalSettings.bookings.viewAllOrgBookings.value}
                                                    label="View all organisation's bookings"
                                                    onClick={checkSettingOnClick}
                                                    onChange={onChange}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    id="viewOverviewAllTimetables"
                                                    name="bookings"
                                                    checked={UserProfileAdminGlobalSettings.bookings.viewOverviewAllTimetables.value}
                                                    label="View overview of all timetable rooms"
                                                    onClick={checkSettingOnClick}
                                                    onChange={onChange}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    id="viewOverviewAllDiaries"
                                                    name="bookings"
                                                    checked={UserProfileAdminGlobalSettings.bookings.viewOverviewAllDiaries.value}
                                                    label="View overview of all diary rooms"
                                                    onClick={checkSettingOnClick}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        ) : null}

                                        {tabs.admin ? (
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_orgAdminAccess"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.orgAdminAccess.value}
                                                            label="Organisation Admin Access"
                                                            disabled
                                                            onChange={onChange}
                                                        />{" "}
                                                        <br />
                                                        Select one section to activite the Organisation Admin Access
                                                    </Col>
                                                    <Col>
                                                        <strong>Sections: </strong>
                                                        <br />
                                                        <br />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_loginSettings"
                                                            name="dministrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_loginSettings.value}
                                                            label="Login Settings"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_departments"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_departments.value}
                                                            label="Departments"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_users"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_users.value}
                                                            label="Users"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_rooms"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_rooms.value}
                                                            label="Rooms"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_layouts"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_layouts.value}
                                                            label="Layouts"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_weekSystemHolidays"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_weekSystemHolidays.value}
                                                            label="Week System / Holidays"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="admin_userProfiles"
                                                            name="administrator"
                                                            checked={UserProfileAdminGlobalSettings.administrator.admin_userProfiles.value}
                                                            label="User Profiles"
                                                            onClick={changeAdminSetting}
                                                            onChange={onChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ) : null}
                                        {tabs.weeks ? (
                                            <div>
                                                <Dropdown>
                                                    Number of weeks to go back:
                                                    <Dropdown.Toggle variant="primary">{settings.weeksTitle}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="0" name="weeks" onClick={changeWeekSettingHandle}>
                                                            0 Weeks
                                                        </Dropdown.Item>
                                                        <Dropdown.Item id="1" name="weeks" onClick={changeWeekSettingHandle}>
                                                            1 Week
                                                        </Dropdown.Item>
                                                        <Dropdown.Item id="2" name="weeks" onClick={changeWeekSettingHandle}>
                                                            2 Weeks
                                                        </Dropdown.Item>
                                                        <Dropdown.Item id="4" name="weeks" onClick={changeWeekSettingHandle}>
                                                            4 Weeks
                                                        </Dropdown.Item>
                                                        <Dropdown.Item id="8" name="weeks" onClick={changeWeekSettingHandle}>
                                                            8 Weeks
                                                        </Dropdown.Item>
                                                        <Dropdown.Item id="12" name="weeks" onClick={changeWeekSettingHandle}>
                                                            12 Weeks
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <br />
                                                You can only go back up to 12 weeks
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={UserProfilesStyles.alignRight}>
                            {!settings.defaultPro ? (
                                <Button variant="primary" onClick={handleUpdateProfile}>
                                    Update
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </CenterContainer>
            </Container>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={help.open} onHide={handleCloseHelp}>
                <Modal.Header closeButton>
                    <Modal.Title>{help.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {help.message}
                    <br /> <br /> {help.message2}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseHelp}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ProfileSettings;
