import * as Colors from "../../../globalStyles/colors";

export const betaBanner = {
    backgroundColor: Colors.primary,
    color: "red"
};

export const clickableTitle = {
    color: "red",
    backgroundColor: Colors.primary
};

export const userNav = {
    textAlign: "right",
    paddingRight: "20px",
    fontSize: "1.5rem",
    backgroundColor: Colors.primary,
    color: "red"
};

export const background = {
    backgroundColor: Colors.primary
};
