import * as ReducerActions from "../actions/preBuiltFeatures";

const initialState = {
	features: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.SET_SETTINGS:
			return action.value;
		default:
			return state;
	}
};
