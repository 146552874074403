export const UPDATE_ROOM_NAME = "UPDATE_ROOM_NAME";
export const UPDATE_ROOM_ID = "UPDATE_ROOM_ID";
export const UPDATE_ROOM_LAYOUT_DATA = "UPDATE_ROOM_LAYOUT_DATA";
export const UPDATE_ROOM_SESSION_ID = "UPDATE_ROOM_SESSION_ID";
export const UPDATE_ROOM_SESSION_LABEL = "UPDATE_ROOM_SESSION_LABEL";
export const UPDATE_ROOM_DATE = "UPDATE_ROOM_DATE";
export const UPDATE_ROOM_WEEK_BEGIN = "UPDATE_ROOM_WEEK_BEGIN";
export const UPDATE_ROOM_TOTAL_SESSIONS = "UPDATE_ROOM_TOTAL_SESSIONS";
export const UPDATE_ROOM_DAY_LIST = "UPDATE_ROOM_DAY_LIST";
export const UPDATE_ROOM_WEEK_SYSTEM = "UPDATE_ROOM_WEEK_SYSTEM";
export const UPDATE_ROOM_WEEK_UUID = "UPDATE_ROOM_WEEK_UUID";
export const UPDATE_FORCE_COLLIDED_BOOKINGS = "UPDATE_FORCE_COLLIDED_BOOKINGS";
export const UPDATE_DIARY_START_TIME = "UPDATE_DIARY_START_TIME";
export const UPDATE_DIARY_FINISH_TIME = "UPDATE_DIARY_FINISH_TIME";
export const UPDATE_DIARY_TIME_INTERVAL = "UPDATE_DIARY_TIME_INTERVAL";
export const RESET_TO_DEFAULTS = "RESET_TO_DEFAULTS";

export const UPDATE_FROM_SIGNIN = "UPDATE_FROM_SIGNIN";

export const UpdateRoomName = (val) => {
	return {
		type: UPDATE_ROOM_NAME,
		value: val,
	};
};

export const UpdateRoomID = (val) => {
	return {
		type: UPDATE_ROOM_ID,
		value: val,
	};
};

export const UpdateRoomLayoutData = (val) => {
	return {
		type: UPDATE_ROOM_LAYOUT_DATA,
		value: val,
	};
};

export const UpdateRoomSessionID = (val) => {
	return {
		type: UPDATE_ROOM_SESSION_ID,
		value: val,
	};
};

export const UpdateRoomSessionLabel = (val) => {
	return {
		type: UPDATE_ROOM_SESSION_LABEL,
		value: val,
	};
};

export const UpdateRoomDate = (val) => {
	return {
		type: UPDATE_ROOM_DATE,
		value: val,
	};
};

export const UpdateRoomWeekBegin = (val) => {
	return {
		type: UPDATE_ROOM_WEEK_BEGIN,
		value: val,
	};
};

export const UpdateRoomTotalSessions = (val) => {
	return {
		type: UPDATE_ROOM_TOTAL_SESSIONS,
		value: val,
	};
};

export const UpdateRoomDayList = (val) => {
	return {
		type: UPDATE_ROOM_DAY_LIST,
		value: val,
	};
};

export const UpdateRoomWeekSystem = (val) => {
	return {
		type: UPDATE_ROOM_WEEK_SYSTEM,
		value: val,
	};
};

export const UpdateRoomWeekUUID = (val) => {
	return {
		type: UPDATE_ROOM_WEEK_UUID,
		value: val,
	};
};

export const CollideBookingsForceUpdate = (val) => {
	return {
		type: UPDATE_FORCE_COLLIDED_BOOKINGS,
		value: val,
	};
};

export const UpdateDiaryStartTime = (val) => {
	return {
		type: UPDATE_DIARY_START_TIME,
		value: val,
	};
};

export const UpdateDiaryFinishTime = (val) => {
	return {
		type: UPDATE_DIARY_FINISH_TIME,
		value: val,
	};
};

export const UpdateDiaryTimeInterval = (val) => {
	return {
		type: UPDATE_DIARY_TIME_INTERVAL,
		value: val,
	};
};

export const ResetToDefaults = () => {
	return {
		type: RESET_TO_DEFAULTS,
	};
};

export const ResetDefaults = {
	roomName: "",
	roomID: "",
	sessionID: "",
	sessionLabel: "",
	date: "",
	weekBeginDate: "",
	totalSessions: 0,
	dayList: [],
	layoutData: {},
	weekSystem: false,
	weekUUID: "",
	startTime: "",
	finishTime: "",
	timeInterval: "",
};
