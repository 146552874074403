import * as Colors from "../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem",
    textAlign: "center"
};

export const blueHeading = {
    color: "blue"
};

export const mxAuto = {
    fontSize: "1.3rem",
    color: "white"
};

export const navLink = {
    paddingLeft: "30px",
    paddingLight: "30px"
};

export const navBar = {
    display: "flex",
    flexDirection: "column",
    contentAlign: "center",
    backgroundColor: Colors.background,
    marginLeft: "20%",
    marginRight: "20%"
};
