export const UpdateUUID = (val) => {
    return {
        type: 'UPDATE_ADMINP_UUID',
        value: val
    };
}

export const UpdateName = (val) => {
    return {
        type: 'UPDATE_ADMINP_NAME',
        value: val
    };
}