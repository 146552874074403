import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//ACTIONS
import * as UserActions from "../../../../../../store/actions/user";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function ChangeOfSeniorRequest() {
    const params = useParams();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [settings, setSettings] = useState({
        organisation: {}
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        navigate("/", { replace: true });

        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        const data = { orgID: params.orgID };
        Axios.post("/security/getSARData", data)
            .then((res) => {
                console.log(res.data.org);
                setSettings({ organisation: res.data.org });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleAccept() {
        const data = { orgID: params.orgID, invate: "Accept", newUserID: user.uuid };
        Axios.post("/security/SAInvate", data)
            .then((res) => {
                console.log(res.data);
                if (res.data.message == "Senior Admin Updated") {
                    dispatch(UserActions.userUpdateRole(res.data.role));
                    dispatch(UserActions.userUpdateSARequest(""));

                    setModal({ heading: "Request Accepted", open: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleDecline() {
        const data = { orgID: params.orgID, invate: "Decline", newUserID: user.uuid };
        Axios.post("/security/SAInvate", data)
            .then((res) => {
                if (res.data.message == "Senior Admin Request Declined") {
                    dispatch(UserActions.userUpdateSARequest(""));

                    navigate("/", { replace: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={{ ...SecurityStyles.body, ...SecurityStyles.changeOfSenior }}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>Change of Senior Admin Request</h1>
                    <br />
                    <p>You are viewing this screen because {settings.organisation.POC_Name} has requested a change of Senior Admin role to you. </p>
                    <p>This means that you will be responible for the admin side of your organisation.</p>
                    <p>If you require help, please speak to {settings.organisation.POC_Name} or contact High-View Studios</p>
                    <p>Do you want to Accept or Decline this invitation?</p>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={handleAccept}>
                                Accept
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleDecline}>
                                Decline
                            </Button>
                        </Col>
                    </Row>
                </CenterContainer>
            </Container>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are now the Senior Admin of this organisation.</p>
                    <p>To get to the Admin side, go on the organisation page drop-down at the top-right of the screen</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ChangeOfSeniorRequest;
