import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";

//ACTIONS
import * as OrganisationActions from "../../store/actions/organistion";
import * as BookingsActions from "../../store/actions/bookings";
import * as RoomDetailsActions from "../../store/actions/roomDetails";
import * as UserProfileActions from "../../store/actions/userProfile";
import * as preBuiltFeaturesActions from "../../store/actions/preBuiltFeatures";

//COMPONENTS
import Book from "./frontEnd/pods/bookings/files/newBook";
import OrganisationHome from "./frontEnd/organisationHome";
import ChangePassword from "./frontEnd/pods/security/files/changePassword";
import CreatePassword from "./frontEnd/pods/security/files/createPassword";
import ForgotPassword from "./frontEnd/pods/security/files/forgotPassword";
import SignIn from "./frontEnd/pods/security/files/signIn";
import WrongOrganisation from "./frontEnd/pods/security/files/wrongOrganisation";
import WrongLogin from "./frontEnd/pods/security/files/wrongLogin";

import CollisionBookings from "./admin/pods/weekSystemHolidays/files/collisionBookings";
import ChangeofSeniorRequest from "./frontEnd/pods/security/files/changeOfSeniorRequest";
import UserDetails from "./admin/pods/users/files/userDetails";

import OrganisationAdmin from "./admin/organisationAdmin";
import OrganisationBookings from "./admin/organisationBookings";
import ProfileSettings from "./admin/pods/userProfiles/files/profileSettings";

function OrganisationRouter(props) {
    const params = useParams();
    const orgID = params.id;
    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);
    const UserProfileAdminGlobalSettings = useSelector((state) => state.UserProfileAdminGlobalSettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        document.title = "STAFF";
        getOrgisation();
    }, []);

    function getOrgisation() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        dispatch(RoomDetailsActions.UpdateShowSwitch(false));
        const data = { orgID: params.orgID, userProfiles: user.profiles, userSettings: UserProfileAdminGlobalSettings };
        // console.log(data);
        Axios.post("/orgRouter/getOrganisation", data)
            .then((res) => {
                console.log(res.data);
                if (res.data.error != "Yes") {
                    dispatch(OrganisationActions.orgUpdateName(res.data.organisation.name));
                    dispatch(OrganisationActions.orgUpdateSignInLocal(res.data.organisation.auth_Local == "true"));
                    dispatch(OrganisationActions.orgUpdateSignInGoogle(res.data.organisation.auth_Google == "true"));
                    dispatch(OrganisationActions.orgUpdateMessage(res.data.organisation.message));
                    dispatch(OrganisationActions.orgUpdateUseDepartments(res.data.organisation.useDepartments == "true"));
                    dispatch(OrganisationActions.orgUpdateNoOfDepartments(res.data.organisation.noOfDepartments));
                    dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));
                    dispatch(OrganisationActions.orgUpdateAllocatedRooms(res.data.organisation.allocatedRooms));
                    dispatch(OrganisationActions.orgUpdateRedeemedRooms(res.data.organisation.redeemedRooms));
                    dispatch(OrganisationActions.orgUpdateRooms(res.data.rooms));
                    dispatch(OrganisationActions.orgUpdateLayouts(res.data.layouts));
                    dispatch(OrganisationActions.orgUpdateHolidays(res.data.holidays));
                    dispatch(OrganisationActions.orgUpdateLocked(res.data.organisation.locked == "true"));
                    dispatch(OrganisationActions.orgUpdateNewUserPasswords(res.data.organisation.newUserPasswords == "true"));
                    dispatch(OrganisationActions.orgUpdateResetUserPasswords(res.data.organisation.resetUserPasswords == "true"));
                    dispatch(preBuiltFeaturesActions.SetPreBuiltFeatures(res.data.features));

                    //USER PROFILE
                    if (!(res.data.organisation.useProfiles == "true") || res.data.userProfile.default) {
                        //default
                        dispatch(UserProfileActions.DefaultSettings());
                    } else {
                        dispatch(
                            UserProfileActions.Update_User_Settings(
                                res.data.userProfile.rooms,
                                res.data.userProfile.bookings,
                                res.data.userProfile.administrator,
                                res.data.userProfile.weeks
                            )
                        );
                    }

                    if (user.auth == false) {
                        navigate("/org/" + params.orgID + "/signIn", { replace: true });
                    } else {
                        if (user.orgID != params.orgID) {
                            navigate("/org/" + user.orgID + "/wrongOrganisation", { replace: true });
                        } else if (user.requestedPassword == "true") {
                            navigate("/org/" + params.orgID + "/changePassword", { replace: true });
                        } else if (user.new == "true") {
                            navigate("/org/" + params.orgID + "/createPassword", { replace: true });
                        } else if (user.SARequest == "true") {
                            navigate("/org/" + params.orgID + "/changeOfSeniorRequest"), { replace: true };
                        } else {
                            if (globalVars.fromSignIn) {
                                navigate("/org/" + params.orgID, { replace: true });
                            }
                        }
                    }
                    setLoaded(true);
                } else {
                    navigate("/", { replace: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            {isLoaded ? (
                <div>
                    <Routes>
                        <Route path={"/"} element={<OrganisationHome />} />
                        <Route path={"book"} element={<Book />} />
                        <Route path={"/collisionBookings"} element={<CollisionBookings />} />

                        <Route path={"/organisationAdmin"} element={<OrganisationAdmin />} />
                        <Route path={"/organisationBookings"} element={<OrganisationBookings />} />
                        <Route path={"/userDetails"} element={<UserDetails />} />
                        <Route path={"/profileSettings"} element={<ProfileSettings />} />

                        <Route path={"/signIn"} element={<SignIn />} />
                        <Route path={"/forgotPassword"} element={<ForgotPassword />} />
                        <Route path={"/changePassword"} element={<ChangePassword />} />
                        <Route path={"/changeOfSeniorRequest"} element={<ChangeofSeniorRequest />} />
                        <Route path={"/createPassword"} element={<CreatePassword />} />
                        <Route path={"/wrongOrganisation"} element={<WrongOrganisation />} />
                    </Routes>
                </div>
            ) : null}
        </div>
    );
}

export default OrganisationRouter;
