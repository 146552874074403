import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import ServerPath, { hostPath } from "../../../../../../ServerPath";

//ACTIONS
import * as UserActions from "../../../../../../store/actions/user";

//COMPONENT
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function SignIn(props) {
    const params = useParams();

    const user = useSelector((state) => state.user);
    const organisation = useSelector((state) => state.organisation);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [message, setmessage] = useState("");

    useEffect(() => {
        document.title = "STAFF - Sign In";
        ServerPath();
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth) {
            navigate("/");
        }
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            logIn(event);
        }
    }

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer>
                    <h1>{organisation.name}</h1> <br />
                    <h3>Please Sign In!</h3>
                    <p>{organisation.message}</p>
                    {organisation.signInLocal ? (
                        <div>
                            <form>
                                <label id="lblEmail">Email:</label>
                                <br />
                                <input id="txtEmail" type="email"></input>
                                <br />
                                <br />
                                <label id="lblPassword">Password:</label>
                                <br />
                                <input id="txtPassword" type="password" onKeyPress={handleKeyPress}></input>
                                <br /> <br />
                                <p>{message}</p>
                            </form>
                            <br />

                            <Button variant="warning" onClick={logIn}>
                                Log in
                            </Button>
                            <Button variant="warning" onClick={() => navigate("/org/" + params.orgID + "/forgotPassword")}>
                                Forgot Password
                            </Button>
                            <br />
                            <br />
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {organisation.signInGoogle ? (
                        <Button variant="danger" href={hostPath + "/userLogin/auth/google"}>
                            <i className="fab fa-google"></i> Log in with Google
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </CenterContainer>
            </Container>
        </div>
    );

    function logIn(event) {
        event.preventDefault();
        const email = document.getElementById("txtEmail").value;
        const password = document.getElementById("txtPassword").value;

        const data = { email: email, password: password };
        Axios.post("/userLogin/login", data)
            .then((res) => {
                console.log(res.data);
                const message = res.data.message;

                if (message === "Logged in successful") {
                    dispatch(UserActions.userUpdateAuth(true));
                    dispatch(UserActions.userUpdateUUID(res.data.uuid));
                    dispatch(UserActions.userUpdateName(res.data.displayName));
                    dispatch(UserActions.userUpdateUserDepartments(res.data.departments));
                    dispatch(UserActions.userUpdateRole(res.data.role));
                    dispatch(UserActions.userUpdateNew(res.data.new));
                    dispatch(UserActions.userUpdateRequestedPassword(res.data.requestedPassword));
                    dispatch(UserActions.userUpdateOrgID(res.data.orgID));
                    dispatch(UserActions.userUpdateSARequest(res.data.SARequest));
                    dispatch(UserActions.userUpdateProfiles(res.data.userProfiles));

                    navigate("/");
                } else {
                    setmessage(res.data.info);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

export default SignIn;
