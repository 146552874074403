import * as ReducerActions from "../actions/organistion";

const initialState = {
	name: "",
	signInLocal: false,
	signInGoogle: false,
	message: "",
	useDepartments: false,
	noOfDepartments: "",
	departments: [],
	allocatedRooms: 0,
	redeemedRooms: 0,
	rooms: [],
	layouts: [],
	holidays: [],
	locked: false,
	newUserPasswords: false,
	resetUserPasswords: false,
};

const organisationDetails = (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.ORG_UPDATE_NAME:
			return { ...state, name: action.value };
		case ReducerActions.ORG_UPDATE_SIGNIN_LOCAL:
			return { ...state, signInLocal: action.value };
		case ReducerActions.ORG_UPDATE_SIGNIN_GOOGLE:
			return { ...state, signInGoogle: action.value };
		case ReducerActions.ORG_UPDATE_MESSAGE:
			return { ...state, message: action.value };
		case ReducerActions.ORG_UPDATE_USEDEPARTMENTS:
			return { ...state, useDepartments: action.value };
		case ReducerActions.ORG_UPDATE_NOOFDEPARTMENTS:
			return { ...state, noOfDepartments: action.value };
		case ReducerActions.ORG_UPDATE_DEPARTMENTS:
			return { ...state, departments: action.value };
		case ReducerActions.ORG_UPDATE_ALLOCATEDROOMS:
			return { ...state, allocatedRooms: action.value };
		case ReducerActions.ORG_UPDATE_REDEEMEDROOMS:
			return { ...state, redeemedRooms: action.value };
		case ReducerActions.ORG_UPDATE_ROOMS:
			return { ...state, rooms: action.value };
		case ReducerActions.ORG_UPDATE_LAYOUTS:
			return { ...state, layouts: action.value };
		case ReducerActions.ORG_UPDATE_HOLIDAYS:
			return { ...state, holidays: action.value };
		case ReducerActions.ORG_UPDATE_LOCKED:
			return { ...state, locked: action.value };
		case ReducerActions.ORG_UPDATE_NEWUSERPASSWORDS:
			return { ...state, newUserPasswords: action.value };
		case ReducerActions.ORG_UPDATE_RESETUSERPASSWORDS:
			return { ...state, resetUserPasswords: action.value };
		default:
			return state;
	}
};

export default organisationDetails;
