export const UPDATE_BOOKING_DETAILS = "UPDATE_BOOKING_DETAILS";
export const UPDATE_BOOKING_ID = "UPDATE_BOOKING_ID";
export const UPDATE_BOOKING_USER = "UPDATE_BOOKING_USER";
export const UPDATE_BOOKING_DEPARTMENT = "UPDATE_BOOKING_DEPARTMENT";
export const UPDATE_BOOKING_SESSIONDES = "UPDATE_BOOKING_SESSIONDES";
export const UPDATE_BOOKING_SESSIONLENGTH = "UPDATE_BOOKING_SESSIONLENGTH";
export const UPDATE_BOOKING_BOOKINGTYPE = "UPDATE_BOOKING_BOOKINGTYPE";
export const UPDATE_BOOKING_BOOKINGUNTIL = "UPDATE_BOOKING_BOOKINGUNTIL";
export const UPDATE_BOOKING_COMMENTS = "UPDATE_BOOKING_COMMENTS";
export const UPDATE_BOOKING_CREATEDBY = "UPDATE_BOOKING_CREATEDBY";
export const UPDATE_BOOKING_SLOTID = "UPDATE_BOOKING_SLOTID";

export const UpdateBookingDetails = (val) => {
	return {
		type: UPDATE_BOOKING_DETAILS,
		value: val,
	};
};

export const UpdateBookingID = (val) => {
	return {
		type: UPDATE_BOOKING_ID,
		value: val,
	};
};

export const UpdateBookingUser = (val) => {
	return {
		type: UPDATE_BOOKING_USER,
		value: val,
	};
};

export const UpdateBookingDepartment = (val) => {
	return {
		type: UPDATE_BOOKING_DEPARTMENT,
		value: val,
	};
};

export const UpdateBookingSessionDes = (val) => {
	return {
		type: UPDATE_BOOKING_SESSIONDES,
		value: val,
	};
};

export const UpdateBookingSessionLength = (val) => {
	return {
		type: UPDATE_BOOKING_SESSIONLENGTH,
		value: val,
	};
};

export const UpdateBookingBookingType = (val) => {
	return {
		type: UPDATE_BOOKING_BOOKINGTYPE,
		value: val,
	};
};

export const UpdateBookingBookingUntil = (val) => {
	return {
		type: UPDATE_BOOKING_BOOKINGUNTIL,
		value: val,
	};
};

export const UpdateBookingComments = (val) => {
	return {
		type: UPDATE_BOOKING_COMMENTS,
		value: val,
	};
};

export const UpdateBookingCreatedBy = (val) => {
	return {
		type: UPDATE_BOOKING_CREATEDBY,
		value: val,
	};
};

export const UpdateSlotID = (val) => {
	return {
		type: UPDATE_BOOKING_SLOTID,
		value: val,
	};
};
