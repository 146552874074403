import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import ServerPath from "../../../../../../ServerPath";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function NotConnected() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "STAFF - Not Connected";
        ServerPath();
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth) {
            navigate("/");
        }
    }

    return (
        <div style={SecurityStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h3>Email is not connected to any organisation</h3>
                    <Button variant="warning" onClick={() => navigate("/")}>
                        Back to Home
                    </Button>
                    <br />
                    <br />
                </CenterContainer>
            </Container>
        </div>
    );
}

export default NotConnected;
