export const SET_SETTINGS = "SET_SETTINGS";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_ADMIN_SETTINGS = "UPDATE_ADMIN_SETTINGS";

export const SetSettings = (newSettings) => {
	return {
		type: SET_SETTINGS,
		values: newSettings,
	};
};

export const UpdateSetting = (section, setting, value) => {
	return {
		type: UPDATE_SETTING,
		section: section,
		setting: setting,
		value: value,
	};
};

export const UpdateAdministratorSettings = (setting, value) => {
	return (dispatch, getState) => {
		const administrator = getState().UserProfileAdminGlobalSettings.administrator;

		let mainAdminSetting;
		administrator[setting].value = value;

		if (
			administrator.admin_loginSettings.value ||
			administrator.admin_departments.value ||
			administrator.admin_users.value ||
			administrator.admin_rooms.value ||
			administrator.admin_layouts.value ||
			administrator.admin_weekSystemHolidays.value ||
			administrator.admin_userProfiles.value
		) {
			mainAdminSetting = true;
		} else {
			mainAdminSetting = false;
		}

		dispatch({
			type: UPDATE_ADMIN_SETTINGS,
			mainSetting: mainAdminSetting,
			setting: setting,
			value: value,
		});
	};
};
