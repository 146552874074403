import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";

//COMPONENTS
import ServerPath, { hostPath } from "../../../../ServerPath";
import CenterContainer from "../../../../globalStyles/centerContainer.js";

//STYLES
import * as AdminStyles from "../styles/admin";

function SignIn() {
    const [granted, setGranted] = useState(true);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        ServerPath();
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth) {
            if (user.role == "superAdmin") {
                navigate("/");
            } else {
                setGranted(false);
            }
        }
    }

    return (
        <div style={AdminStyles.body}>
            <Container className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h1>Administrator Sign In</h1>
                    {!granted ? (
                        <div>
                            <h1>Access Denied</h1>
                            <Button variant="warning" href="/">
                                Back to Home
                            </Button>
                            <br />
                            <br />
                        </div>
                    ) : (
                        <div>
                            <br />
                            <Button variant="dark" href={hostPath + "/userLogin/auth/github"}>
                                <i className="fab fa-github"></i> Log in with Github
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                </CenterContainer>
            </Container>
        </div>
    );
}

export default SignIn;
