import * as ReducerActions from "../actions/bookingData";

const initalState = {
	orgID: "",
	roomID: "",
	week: "",
	days: "",
	weekBG: "",
	data: {},
};

export default (state = initalState, action) => {
	switch (action.type) {
		case ReducerActions.GET_DATA:
			return {
				...state,
				orgID: action.orgID,
				data: action.data,
				roomID: action.roomID,
				week: action.week,
				days: action.days,
				weekBG: action.weekBG,
			};
		case ReducerActions.SET_DATA_SLOTS:
			return { ...state, data: action.value };
		case ReducerActions.UPDATE_DATA:
			return { ...state, data: action.data };
		default:
			return state;
	}
};
