import * as Colors from "../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem",
    textAlign: "center",
    minHeight: "100vh"
};

export const rooms = {
    float: "left"
};

export const roomLbl = {
    padding: "15px",
    float: "left"
};

export const noRoomsLbl = {
    paddingTop: "15px",
    paddingBottom: "15px",
    float: "left"
};

export const dropdownItems = {
    maxHeight: "150px",
    overflowY: "auto"
};

export const rowNothing = {
    visibility: "hidden"
};

export const topRightMenu = {
    textAlign: "right"
};

export const filterSetting = {
    float: "left",
    fontSize: "1.5rem",
    cursor: "pointer"
};
