export const bordered = {
    border: "solid 1px",
    borderColor: "black"
};

export const hidden = {
    visibility: "hidden"
};

export const show = {
    visibility: "visible"
};

export const addButton = {
    float: "right"
};

export const width100 = {
    width: "100%"
};

export const submitButtonShow = {
    float: "left"
};
