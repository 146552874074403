export const content = {
    maxHeight: 0,
    overflow: "hidden",
    transition: "all 0.5s cubic-bezier(0,1,0,1)"
};

export const contentShow = {
    height: "auto",
    maxHeight: "9999px",
    overflow: "hidden",
    transition: "all 0.5s cubic-bezier(1,0,1,0)"
};

export const card = {
    cursor: "pointer"
};

export const sortBox = {
    cursor: "pointer",
    padding: "auto"
};
