import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";

//ACTIONS
import * as RoomDetailsActions from "../../../../../store/actions/roomDetails";
import * as GlobalVarsActions from "../../../../../store/actions/globalVars";
import * as BookingsActions from "../../../../../store/actions/bookings";
import * as BookingDataActions from "../../../../../store/actions/bookingData";

//STYLES
import * as TemplatesStyles from "../../styles/pods/templates";

function Timetable(props) {
    const orgID = props.orgID;
    const organisation = useSelector((state) => state.organisation);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);
    const bookings = useSelector((state) => state.bookings);
    const bookingData = useSelector((state) => state.bookingData);
    const user = useSelector((state) => state.user);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [settings, setSettings] = useState({
        slotClass: "",
        dayClass: "",
        dates: ["", "", "", "", "", "", ""],
        dayIndex: 0,
        holiday: "",
        weekSystemUUID: "",
        currentDay: "",
        currentDayClass: "",
        pastWeek: false
    });

    const [layout, setLayout] = useState({
        days: [],
        order: [],
        sessions: [],
        sessionTotal: 0
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        setup();
    }, [props.roomName]);

    function setup() {
        console.log("setup");
        //SETUP NEW LAYOUT FROM REDUX
        const layoutDays = props.layoutData.days.split(",");
        const days = [];
        let newSessions = {};
        const order = props.layoutData.sessionOrder.split(",");

        for (const day of layoutDays) {
            days.push(day == "true");
        }

        const data = { orgID: orgID, layoutUUID: props.layoutData.uuid };
        Axios.post("/orgAdmin/layouts/getTimetableSessions", data)
            .then((res) => {
                const sessions = res.data.sessions;

                for (const session of sessions) {
                    newSessions[session.id] = session;
                }

                setLayout((prevState) => {
                    return { ...prevState, days: days, sessions: newSessions, sessionTotal: props.layoutData.sessions, order: order };
                });

                /////

                let totalDays = 0;
                for (const day of days) {
                    if (day) {
                        totalDays++;
                    }
                }
                setSettings((prevState) => {
                    return {
                        ...prevState,
                        slotClass: "session" + totalDays + "_slot",
                        dayClass: "session" + totalDays + "_days",
                        currentDayClass: "session" + totalDays + "_days timetable-layout currentDay"
                    };
                });

                if (globalVars.roomDetail.weekBeginDate == "") {
                    const weekBG = FindWeekBegin(days);
                    BuildDataSlots(weekBG, order, newSessions);
                    CheckHoliday(weekBG);
                    console.log("1");
                } else {
                    console.log("2");
                    FindDayIndex(days);
                    const firstDate = moment(globalVars.roomDetail.weekBeginDate, "DD/MM/YYYY");
                    RebuildDates(firstDate, order, newSessions);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        //SETUP NEW LAYOUT FROM REDUX ^^^
    }

    function reload() {
        //SETUP NEW LAYOUT FROM REDUX
        const layoutDays = props.layoutData.days.split(",");
        const days = [];
        let newSessions = {};
        const order = props.layoutData.sessionOrder.split(",");

        for (const day of layoutDays) {
            days.push(day == "true");
        }

        const data = { orgID: orgID, layoutUUID: props.layoutData.uuid };
        Axios.post("/orgAdmin/layouts/getTimetableSessions", data).then((res) => {
            const sessions = res.data.sessions;

            for (const session of sessions) {
                newSessions[session.id] = session;
            }

            setLayout((prevState) => {
                return { ...prevState, days: days, sessions: newSessions, sessionTotal: props.layoutData.sessions, order: order };
            });

            /////

            let totalDays = 0;
            for (const day of days) {
                if (day) {
                    totalDays++;
                }
            }
            setSettings((prevState) => {
                return {
                    ...prevState,
                    slotClass: "session" + totalDays + "_slot",
                    dayClass: "session" + totalDays + "_days",
                    currentDayClass: "session" + totalDays + "_days timetable-layout currentDay"
                };
            });
        });
        // BuildDataSlots(globalVars.roomDetail.weekBeginDate, order, newSessions);
    }

    function FindDayIndex(days) {
        let dayIndex;
        for (const [index, day] of days.entries()) {
            if (day) {
                dayIndex = index;
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, dayIndex: dayIndex };
        });
    }

    function BuildDataSlots(weekBg, order, sessions) {
        const data = {};
        const days = [];
        const weekBG = moment(weekBg, "DD/MM/YYYY");
        const slot = { uuid: "", user: "", department: "", type: "" };

        const weekSlot = formatString(weekBG.week()) + "-" + weekBG.format("YY");

        for (let i = 0; i < 7; i++) {
            for (const sess of order) {
                days.push(formatString(weekBG.day()) + "-" + formatString(sessions[sess].id));
                const sName =
                    formatString(weekBG.week()) +
                    "-" +
                    weekBG.format("YY") +
                    "-" +
                    formatString(weekBG.day()) +
                    "-" +
                    formatString(sessions[sess].id);
                data[sName] = slot;
            }

            weekBG.add(1, "d");
        }

        dispatch(BookingDataActions.GetData(orgID, props.roomID, weekSlot, days, weekBg));
    }

    function CheckHoliday(weekBG) {
        const date = moment(weekBG, "DD/MM/YYYY");
        const week = formatString(date.week()) + "-" + date.format("YY");
        let holidayTitle = "";

        for (const holiday of organisation.holidays) {
            if (holiday.weeks.includes(week)) {
                if (holiday.titleUUID.includes("w")) {
                    if (props.weekSystem) {
                        holidayTitle = holiday.name;
                        setSettings((prevState) => {
                            return { ...prevState, weekSystemUUID: holiday.titleUUID };
                        });
                    }
                } else if (holiday.titleUUID.includes("h")) {
                    holidayTitle = "Holiday: " + holiday.name;
                }
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, holiday: holidayTitle };
        });
    }

    function formatString(time) {
        if (time.toString().includes("b")) {
            time = time.replace("b", "");

            if (time.toString().length == 1) {
                return "b0" + time;
            } else {
                return time;
            }
        } else {
            if (time.toString().length == 1) {
                return "0" + time;
            } else {
                return time;
            }
        }
    }

    function handleReloadTooltip() {
        ReactTooltip.rebuild();
    }

    function handleBookClick(event) {
        if (user.userDepartments == "") {
            setModal({
                heading: "No Departments",
                message: "You cannot book as you have no department assign to you. Please contact you organisation's senior admin",
                open: true
            });
        } else {
            if (!organisation.locked && userProfile.room_Write) {
                dispatch(BookingsActions.UpdateBookingDetails(false));
                dispatch(RoomDetailsActions.UpdateShowSwitch(true));
                const { id } = event.target;
                const IDs = id.toString().split("-");
                dispatch(GlobalVarsActions.UpdateRoomName(props.roomName));
                dispatch(GlobalVarsActions.UpdateRoomID(props.roomID));
                dispatch(GlobalVarsActions.UpdateRoomSessionID(id));
                dispatch(GlobalVarsActions.UpdateRoomWeekSystem(props.weekSystem));
                dispatch(GlobalVarsActions.UpdateRoomWeekUUID(settings.weekSystemUUID));
                dispatch(GlobalVarsActions.UpdateRoomLayoutData(props.layoutData));

                if (layout.sessions[IDs[1]].id.includes("b")) {
                    dispatch(GlobalVarsActions.UpdateRoomSessionLabel(layout.sessions[IDs[1]].breakText));
                } else {
                    if (layout.sessions[IDs[1]].customText != "") {
                        dispatch(GlobalVarsActions.UpdateRoomSessionLabel(layout.sessions[IDs[1]].customText));
                    } else {
                        dispatch(GlobalVarsActions.UpdateRoomSessionLabel(layout.sessions[IDs[1]].id));
                    }
                }
                dispatch(GlobalVarsActions.UpdateRoomWeekBegin(settings.dates[0]));
                dispatch(GlobalVarsActions.UpdateRoomDate(settings.dates[IDs[0]]));
                dispatch(GlobalVarsActions.UpdateRoomTotalSessions(layout.sessionTotal));
                dispatch(GlobalVarsActions.UpdateRoomDayList(layout.days));

                navigate("/org/" + orgID + "/book");
            }
        }
    }

    function handleBookingDetails(uuid, slotId) {
        if (bookings.bookingID == uuid) {
            dispatch(BookingsActions.UpdateBookingDetails(false));
            dispatch(RoomDetailsActions.UpdateShowSwitch(true));
            dispatch(BookingsActions.UpdateBookingID(0));
            return;
        }

        const data = { orgID: orgID, uuid: uuid };
        Axios.post("/bookings/getBookingData", data)
            .then((res) => {
                const data = res.data;
                dispatch(BookingsActions.UpdateBookingUser(data.booking.user));
                dispatch(BookingsActions.UpdateBookingDepartment(GetDepartment(data.booking.departmentID)));
                dispatch(BookingsActions.UpdateBookingSessionDes(data.booking.sessionDes));
                dispatch(BookingsActions.UpdateBookingSessionLength(data.booking.sessionTotal));

                //SINGLE / REPEAT
                const type = data.booking.bookingType;
                if (type == "single") {
                    dispatch(BookingsActions.UpdateBookingBookingType("Single"));
                } else {
                    dispatch(BookingsActions.UpdateBookingBookingType("Repeat - " + data.booking.repeatType));
                    dispatch(BookingsActions.UpdateBookingBookingUntil(data.booking.repeatUntil));
                }

                dispatch(BookingsActions.UpdateBookingComments(data.booking.comments));
                dispatch(BookingsActions.UpdateBookingCreatedBy(data.booking.createdBy));
                dispatch(BookingsActions.UpdateBookingDetails(true));
                dispatch(RoomDetailsActions.UpdateShowSwitch(false));
                dispatch(BookingsActions.UpdateBookingID(uuid));

                const date = moment(settings.dates[0], "DD/MM/YYYY");
                const week = formatString(date.week()) + "-" + date.format("YY");
                dispatch(BookingsActions.UpdateSlotID(week + "-" + slotId));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function FindWeekBegin(days) {
        let today = moment();
        const currentDay = today.format("DD/MM/YYYY");
        today.startOf("week");

        let dates = [];
        for (let i = 0; i < 7; i++) {
            dates.push(today.format("DD/MM/YYYY"));
            today.add(1, "d");
        }

        setSettings((prevState) => {
            return { ...prevState, dates: dates, currentDay: currentDay };
        });

        let dayIndex;
        for (const [index, day] of days.entries()) {
            if (day) {
                dayIndex = index;
                break;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, dayIndex: dayIndex };
        });

        return dates[0];
    }

    function RebuildDates(firstDate, order, sessions) {
        let dates = [];
        for (let i = 0; i < 7; i++) {
            dates.push(firstDate.format("DD/MM/YYYY"));
            firstDate.add(1, "d");
        }

        //Checks if the color is black (present) or red (past)
        let currentWeek = moment();
        currentWeek.startOf("week");

        let past = false;
        if (moment(dates[0], "DD/MM/YYYY") < currentWeek) {
            past = true;
        }
        ///

        setSettings((prevState) => {
            return { ...prevState, dates: dates, pastWeek: past };
        });

        BuildDataSlots(dates[0], order, sessions);
        CheckHoliday(dates[0]);
    }

    function roomDetailsResetHandler() {
        dispatch(RoomDetailsActions.UpdateShowSwitch(true));
        dispatch(BookingsActions.UpdateBookingID(0));
    }

    function handleAdvancedWeek() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        roomDetailsResetHandler();
        let newDate = moment(settings.dates[0], "DD/MM/YYYY");
        newDate.add(1, "w");

        RebuildDates(newDate, layout.order, layout.sessions);
    }

    function handleGoBackWeek() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        roomDetailsResetHandler();

        let newDate = moment(settings.dates[0], "DD/MM/YYYY");
        newDate.subtract(1, "week");

        let pastDate = moment();
        pastDate.startOf("week");

        pastDate.subtract(parseInt(userProfile.userSettings.weeks.goBackWeeks), "weeks");

        if (newDate < pastDate) {
            setModal({ heading: props.roomName, message: "You cannot go back previous from this week", open: true });
        } else {
            RebuildDates(newDate, layout.order, layout.sessions);
        }
    }

    function GetDepartment(id) {
        for (const department of organisation.departments) {
            if (department.uuid == id) {
                return department.name;
            }
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <strong>
                        <div style={settings.pastWeek ? TemplatesStyles.centredWbRed : TemplatesStyles.centredWb}>
                            Week Beginning: {settings.dates[settings.dayIndex]}
                        </div>
                    </strong>
                </Col>
                <Col>
                    <strong>
                        <div style={TemplatesStyles.centered100}>{settings.holiday}</div>
                    </strong>
                </Col>
                <Col>
                    <div style={TemplatesStyles.sideBySideR}>
                        <Button variant="primary" onClick={handleGoBackWeek}>
                            -
                        </Button>
                        <Button variant="primary" onClick={handleAdvancedWeek}>
                            +
                        </Button>
                    </div>
                </Col>
            </Row>
            <div>
                <Row>
                    <table style={TemplatesStyles.layout} width="100%" border="1px">
                        <thead>
                            <tr>
                                <td style={{ ...TemplatesStyles[settings.slotClass], ...TemplatesStyles.layout }}>Session</td>
                                {layout.days.map((day, index) => {
                                    if (day) {
                                        return (
                                            <td
                                                style={
                                                    settings.currentDay === settings.dates[index]
                                                        ? {
                                                              ...TemplatesStyles[settings.dayClass],
                                                              ...TemplatesStyles.layout,
                                                              ...TemplatesStyles.currentDay
                                                          }
                                                        : { ...TemplatesStyles[settings.dayClass], ...TemplatesStyles.layout }
                                                }
                                                key={index}
                                            >
                                                {dayNames[index]} <br /> {settings.dates[index]}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {layout.order.map((session, index) => {
                                if (session.toString().includes("b")) {
                                    //breaks \/\/
                                    return (
                                        <tr key={index} style={{ backgroundColor: layout.sessions[session].bgColor }}>
                                            <td
                                                style={{
                                                    ...TemplatesStyles[settings.slotClass],
                                                    ...TemplatesStyles.layout,
                                                    color: layout.sessions[session].textColor
                                                }}
                                            >
                                                {layout.sessions[session].breakText != ""
                                                    ? layout.sessions[session].breakText
                                                    : layout.sessions[session].id}
                                            </td>
                                            {layout.days.map((day, index) => {
                                                if (day) {
                                                    if (Object.keys(bookingData.data).length > 0) {
                                                        //console.log(Object.keys(bookingData).length);
                                                        const name =
                                                            bookingData.week +
                                                            "-" +
                                                            formatString(index) +
                                                            "-" +
                                                            formatString(layout.sessions[session].id);
                                                        //console.log(name);
                                                        //console.log(bookingData);
                                                        if (bookingData.data[name].type == "single") {
                                                            return (
                                                                <td
                                                                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles.singleSlot }}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={() =>
                                                                        handleBookingDetails(
                                                                            bookingData.data[name].uuid,
                                                                            formatString(index) + "-" + formatString(session)
                                                                        )
                                                                    }
                                                                >
                                                                    {bookingData.data[name].user} <br />{" "}
                                                                    {GetDepartment(bookingData.data[name].department)}
                                                                </td>
                                                            );
                                                        } else if (bookingData.data[name].type == "repeat") {
                                                            return (
                                                                <td
                                                                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles.repeatSlot }}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={() =>
                                                                        handleBookingDetails(
                                                                            bookingData.data[name].uuid,
                                                                            formatString(index) + "-" + formatString(session)
                                                                        )
                                                                    }
                                                                >
                                                                    {bookingData.data[name].user} <br />{" "}
                                                                    {GetDepartment(bookingData.data[name].department)}
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td
                                                                    className={
                                                                        !organisation.locked && userProfile.room_Write
                                                                            ? "custom_emptySlot"
                                                                            : "custom_emptySlotDisabled"
                                                                    }
                                                                    style={TemplatesStyles.layout}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={handleBookClick}
                                                                >
                                                                    Book
                                                                </td>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <td
                                                                className={
                                                                    !organisation.locked && userProfile.room_Write
                                                                        ? "custom_emptySlot"
                                                                        : "custom_emptySlotDisabled"
                                                                }
                                                                style={TemplatesStyles.layout}
                                                                key={index}
                                                                id={index + "-" + session}
                                                                onClick={handleBookClick}
                                                            >
                                                                Book
                                                            </td>
                                                        );
                                                    }
                                                }
                                            })}
                                        </tr>
                                    );
                                } else {
                                    //sessions \/\/
                                    return (
                                        <tr key={index}>
                                            <td
                                                style={{ ...TemplatesStyles[settings.slotClass], ...TemplatesStyles.layout }}
                                                // data-tip={layout.sessions[session].hoverText != "" ? layout.sessions[session].hoverText : null}
                                            >
                                                {layout.sessions[session].customText != ""
                                                    ? layout.sessions[session].customText
                                                    : layout.sessions[session].id}
                                            </td>
                                            {layout.days.map((day, index) => {
                                                if (day) {
                                                    if (Object.keys(bookingData.data).length > 0) {
                                                        const name =
                                                            bookingData.week +
                                                            "-" +
                                                            formatString(index) +
                                                            "-" +
                                                            formatString(layout.sessions[session].id);
                                                        if (bookingData.data[name].type == "single") {
                                                            return (
                                                                <td
                                                                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles.singleSlot }}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={() =>
                                                                        handleBookingDetails(
                                                                            bookingData.data[name].uuid,
                                                                            formatString(index) + "-" + formatString(session)
                                                                        )
                                                                    }
                                                                >
                                                                    {bookingData.data[name].user} <br />{" "}
                                                                    {GetDepartment(bookingData.data[name].department)}
                                                                </td>
                                                            );
                                                        } else if (bookingData.data[name].type == "repeat") {
                                                            return (
                                                                <td
                                                                    style={{ ...TemplatesStyles.layout, ...TemplatesStyles.repeatSlot }}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={() =>
                                                                        handleBookingDetails(
                                                                            bookingData.data[name].uuid,
                                                                            formatString(index) + "-" + formatString(session)
                                                                        )
                                                                    }
                                                                >
                                                                    {bookingData.data[name].user} <br />{" "}
                                                                    {GetDepartment(bookingData.data[name].department)}
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td
                                                                    className={
                                                                        !organisation.locked && userProfile.room_Write
                                                                            ? "custom_emptySlot"
                                                                            : "custom_emptySlotDisabled"
                                                                    }
                                                                    style={TemplatesStyles.layout}
                                                                    key={index}
                                                                    id={index + "-" + session}
                                                                    onClick={handleBookClick}
                                                                >
                                                                    Book
                                                                </td>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <td
                                                                className={
                                                                    !organisation.locked && userProfile.room_Write
                                                                        ? "custom_emptySlot"
                                                                        : "custom_emptySlotDisabled"
                                                                }
                                                                style={TemplatesStyles.layout}
                                                                key={index}
                                                                id={index + "-" + session}
                                                                onClick={handleBookClick}
                                                            >
                                                                Book
                                                            </td>
                                                        );
                                                    }
                                                }
                                            })}
                                        </tr>
                                    );
                                }
                            })}
                            {handleReloadTooltip()}
                        </tbody>
                    </table>
                    <ReactTooltip />
                </Row>
            </div>

            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Timetable;
