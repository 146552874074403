import Axios from "axios";

export const GET_DATA = "GET_DATA";
export const SET_DATA_SLOTS = "SET_DATA_SLOTS";
export const UPDATE_DATA = "UPDATE_DATA";

export const GetData = (orgID, roomID, week, days, weekBG) => {
    return (dispatch) => {
        const mySQLData = { orgID: orgID, room: roomID, week: week, days: days, weekBG: weekBG };

        Axios.post("/bookings/getBookings", mySQLData)
            .then((res) => {
                const recievedData = res.data;
                dispatch({
                    type: GET_DATA,
                    data: recievedData.bookings,
                    orgID: orgID,
                    roomID: roomID,
                    week: week,
                    days: days,
                    weekBG: weekBG
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const SetDataSlots = (val) => {
    return {
        type: SET_DATA_SLOTS,
        value: val
    };
};

export const UpdateData = () => {
    return (dispatch, getState) => {
        const bookingData = getState().bookingData;

        const mySQLData = {
            orgID: bookingData.orgID,
            room: bookingData.roomID,
            week: bookingData.week,
            days: bookingData.days,
            weekBG: bookingData.weekBG
        };

        Axios.post("/bookings/getBookings", mySQLData)
            .then((res) => {
                const recievedData = res.data;

                dispatch({
                    type: UPDATE_DATA,
                    data: recievedData.bookings
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
