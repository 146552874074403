export const ROOMDETAILS_SHOW = "ROOMDETAILS_SHOW";
export const ROOMDETAILS_ID = "ROOMDETAILS_ID";

export const UpdateShowSwitch = (show) => {
	return (dispatch, getState) => {
		let detailsShow = false;
		const organisation = getState().organisation;
		const roomDetails = getState().roomDetails;

		const room = organisation.rooms.find((room) => room.uuid === roomDetails.id);

		if (show && room.details === "True") {
			detailsShow = true;
		}

		dispatch({
			type: ROOMDETAILS_SHOW,
			value: detailsShow,
		});
	};
};

export const UpdateId = (val) => {
	return {
		type: ROOMDETAILS_ID,
		value: val,
	};
};
