export const roomsLbl = {
    float: "left",
    padding: "15px"
};

export const rooms = {
    float: "left"
};

export const sortBox = {
    cursor: "pointer",
    padding: "auto"
};
