import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";

//STYLES
import * as YourBookingsStyles from "../../styles/yourBookings";

function BookingItem(props) {
    const [open, setOpen] = useState(false);

    function handleBookingClicked() {
        setOpen(!open);
    }

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div>
            <Card style={YourBookingsStyles.card} onClick={handleBookingClicked}>
                <Card.Body>
                    <Row>
                        <Col>{props.details.startDate}</Col>
                        <Col>{props.details.room}</Col>
                        <Col>{props.admin ? props.details.user : props.details.session}</Col>
                        <Col>{props.admin ? props.details.session : props.details.sessionDes}</Col>
                        <Col>{CapsFirstLetter(props.details.bookingType)}</Col>
                        <Col>{open ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}</Col>
                    </Row>
                    <Row style={open ? YourBookingsStyles.contentShow : YourBookingsStyles.content}>
                        <Col>
                            <br />
                            {props.admin ? (
                                <>
                                    <strong>Class:</strong> <br />
                                    {props.details.sessionDes}
                                </>
                            ) : (
                                <>
                                    <strong>User:</strong> <br />
                                    {props.details.user}
                                </>
                            )}
                        </Col>
                        <Col>
                            {props.details.bookingType == "repeat" && (
                                <>
                                    <br />
                                    <strong>Repeat Type:</strong> <br />
                                    {CapsFirstLetter(props.details.repeatType)}
                                </>
                            )}
                        </Col>
                        <Col>
                            {props.details.bookingType == "repeat" && (
                                <>
                                    <br />
                                    <strong>Repeat Until:</strong> <br />
                                    {props.details.repeatUntil}
                                </>
                            )}
                        </Col>
                        <Col>
                            <br /> <strong>Date Created:</strong> <br />
                            {props.details.dateCreated} <br />
                        </Col>
                        <Col>
                            <br />
                            <strong>Created By:</strong> <br />
                            {props.details.createdBy}
                        </Col>
                        <Col></Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default BookingItem;
