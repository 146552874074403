import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import CenterContainer from "../../../../../../globalStyles/centerContainer";

//STYLES
import * as SecurityStyles from "../styles/security";

function OrganisationNotFound() {
    const navigate = useNavigate();
    const HomePageGlobalSettings = useSelector((state) => state.HomePageGlobalSettings);

    function home() {
        navigate("/");
    }

    useEffect(() => {
        document.title = "STAFF";
    }, []);

    return (
        <div style={SecurityStyles.body}>
            <Container fluid className="p-3">
                <CenterContainer ends={1} middle={10}>
                    <h3>Organisation Not Found</h3> <br /> <br />
                    <div style={SecurityStyles.contactSent}>
                        <strong>Don&apos;t know the organisation&apos;s number?</strong>
                        <br />
                        Locate the Senior admin of your organisation or find your registration email to locate the direct URL <br /> <br />
                    </div>
                    <Button variant="primary" onClick={home}>
                        Home
                    </Button>
                    {HomePageGlobalSettings.dataError ? (
                        <div>
                            <br />
                            (data error)
                        </div>
                    ) : null}
                </CenterContainer>
            </Container>
        </div>
    );
}

export default OrganisationNotFound;
