import * as ReducerActions from "../actions/bookings";

const initialState = {
	bookingDetails: false,
	bookingID: 0,
	user: "",
	department: "",
	sessionDes: "",
	sessionLength: "",
	bookingType: "",
	bookingUntil: "",
	comments: "",
	createdBy: "",
	slotId: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.UPDATE_BOOKING_DETAILS:
			return { ...state, bookingDetails: action.value };
		case ReducerActions.UPDATE_BOOKING_ID:
			return { ...state, bookingID: action.value };
		case ReducerActions.UPDATE_BOOKING_USER:
			return { ...state, user: action.value };
		case ReducerActions.UPDATE_BOOKING_DEPARTMENT:
			return { ...state, department: action.value };
		case ReducerActions.UPDATE_BOOKING_SESSIONDES:
			return { ...state, sessionDes: action.value };
		case ReducerActions.UPDATE_BOOKING_SESSIONLENGTH:
			return { ...state, sessionLength: action.value };
		case ReducerActions.UPDATE_BOOKING_BOOKINGTYPE:
			return { ...state, bookingType: action.value };
		case ReducerActions.UPDATE_BOOKING_BOOKINGUNTIL:
			return { ...state, bookingUntil: action.value };
		case ReducerActions.UPDATE_BOOKING_COMMENTS:
			return { ...state, comments: action.value };
		case ReducerActions.UPDATE_BOOKING_CREATEDBY:
			return { ...state, createdBy: action.value };
		case ReducerActions.UPDATE_BOOKING_SLOTID:
			return { ...state, slotId: action.value };
		default:
			return state;
	}
};
