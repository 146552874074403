import { ROOMDETAILS_SHOW, ROOMDETAILS_ID } from "../actions/roomDetails";

const initialState = {
	show: false,
	id: "",
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ROOMDETAILS_SHOW:
			return {
				...state,
				show: action.value,
			};
		case ROOMDETAILS_ID:
			return {
				...state,
				id: action.value,
			};
		default:
			return state;
	}
};
