//NEW USER PROFILE SETTING - place the new setting's default value in here under every role, user, admin and seniorAdmin

export default {
	user: {
		room_View: true,
		room_Write: true,
		room_Edit: false,
		room_Delete: false,
		room_Repeat: false,
		bookings: {
			viewAllUsers: false,
			viewAllDepartments: false,
		},
		administrator: {
			orgAdminAccess: false,
			admin_loginSettings: false,
			admin_departments: false,
			admin_users: false,
			admin_rooms: false,
			admin_layouts: false,
			admin_weekSystemHolidays: false,
			admin_userProfiles: false,
		},
		weeks: {
			goBackWeeks: "0",
		},
	},
	admin: {
		room_View: true,
		room_Write: true,
		room_Edit: true,
		room_Delete: true,
		room_Repeat: true,
		bookings: {
			viewAllUsers: true,
			viewAllDepartments: true,
		},
		administrator: {
			orgAdminAccess: false,
			admin_loginSettings: false,
			admin_departments: false,
			admin_users: false,
			admin_rooms: false,
			admin_layouts: false,
			admin_weekSystemHolidays: false,
			admin_userProfiles: false,
		},
		weeks: {
			goBackWeeks: "0",
		},
	},
	seniorAdmin: {
		room_View: true,
		room_Write: true,
		room_Edit: true,
		room_Delete: true,
		room_Repeat: true,
		bookings: {
			viewAllUsers: true,
			viewAllDepartments: true,
		},
		administrator: {
			orgAdminAccess: true,
			admin_loginSettings: true,
			admin_departments: true,
			admin_users: true,
			admin_rooms: true,
			admin_layouts: true,
			admin_weekSystemHolidays: true,
			admin_userProfiles: true,
		},
		weeks: {
			goBackWeeks: "0",
		},
	},
};

export const newProfileDefaultSettings = {
	viewAllUsers: "false",
	viewAllDepartments: "false",
	orgAdminAccess: "false",
	admin_loginSettings: "false",
	admin_departments: "false",
	admin_users: "false",
	admin_rooms: "false",
	admin_layouts: "false",
	admin_weekSystemHolidays: "false",
	admin_userProfiles: "false",
	goBackWeeks: "0",
};
