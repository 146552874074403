export const roomsAdd = {
    width: "100%"
};

export const roomsHide = {
    visibility: "hidden"
};

export const roomsShow = {
    visibility: "visible"
};

export const layoutText = {
    width: "100%",
    textAlign: "center"
};
