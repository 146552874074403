import React from "react";

//STYLES
import * as NavBarStyles from "./styles/navBarStyles";

function BetaBanner() {
    return (
        <div style={NavBarStyles.betaBanner}>
            <p>
                <strong>This website is a beta verison, please expect errors. Any problems, please contact High-View Studios (Shaun Evans)</strong>
            </p>
        </div>
    );
}

export default BetaBanner;
