import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

//STYLES
import * as BookingsStyles from "../styles/bookings";

function RoomDetails() {
    const organisation = useSelector((state) => state.organisation);
    const roomDetails = useSelector((state) => state.roomDetails);

    const room = organisation.rooms.find((room) => room.uuid === roomDetails.id);

    return (
        <div>
            <strong>Room Details</strong>
            <div style={BookingsStyles.alignLeft}>
                <br />
                <Row>
                    <Col>
                        <strong>Description:</strong>
                    </Col>
                </Row>
                <Row>
                    <Col>{room.description}</Col>
                </Row>
            </div>
        </div>
    );
}

export default RoomDetails;
